import React from 'react'

function ArtmeldenCnt() {
    return (
        
        <div className="colonne">
            <h2>Florenatlas der Stadt Bern</h2>
            <div className="tbsContainer p-4" style={{marginTop:'-10px'}}>
			    <div className="col-md-12" >
                   <h2>Arten Melden</h2>
                   <p><a href="https://www.infoflora.ch/de/mitmachen/daten-melden/" target="_new">Arten melden bei InfoFlora</a></p>          
			    </div>
            </div>
        </div>
        
    )
}

export default ArtmeldenCnt
