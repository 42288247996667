export const grid1x1kmBernCommun = {
    "type": "FeatureCollection",
    "features": [
    { "type": "Feature", "geometry": { "type": "Polygon", "coordinates": [ [ [ 589007.913712617824785, 199343.500864735862706 ], [ 590007.913713499205187, 199343.500864482310135 ], [ 590007.913713756483048, 198343.500864074681886 ], [ 589007.913712874404155, 198343.500864333938807 ], [ 589007.913712617824785, 199343.500864735862706 ] ] ] } },
    { "type": "Feature", "geometry": { "type": "Polygon", "coordinates": [ [ [ 589007.913712874404155, 198343.500864333938807 ], [ 590007.913713756483048, 198343.500864074681886 ], [ 590007.913714013411663, 197343.500863675028086 ], [ 589007.913713130867109, 197343.50086393149104 ], [ 589007.913712874404155, 198343.500864333938807 ] ] ] } },
    { "type": "Feature", "geometry": { "type": "Polygon", "coordinates": [ [ [ 589007.913713130867109, 197343.50086393149104 ], [ 590007.913714013411663, 197343.500863675028086 ], [ 590007.913714269758202, 196343.500863268069224 ], [ 589007.913713386980817, 196343.500863527413458 ], [ 589007.913713130867109, 197343.50086393149104 ] ] ] } },
    { "type": "Feature", "geometry": { "type": "Polygon", "coordinates": [ [ [ 590007.913713499205187, 199343.500864482310135 ], [ 591007.913714380003512, 199343.500864221656229 ], [ 591007.913714637747034, 198343.500863822497195 ], [ 590007.913713756483048, 198343.500864074681886 ], [ 590007.913713499205187, 199343.500864482310135 ] ] ] } },
    { "type": "Feature", "properties": { "left": 590007.91315991525, "top": 198343.4997165215, "right": 591007.91315991525, "bottom": 197343.4997165215, "id": 15 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 590007.913713756483048, 198343.500864074681886 ], [ 591007.913714637747034, 198343.500863822497195 ], [ 591007.91371489490848, 197343.500863418536028 ], [ 590007.913714013411663, 197343.500863675028086 ], [ 590007.913713756483048, 198343.500864074681886 ] ] ] } },
    { "type": "Feature", "properties": { "left": 590007.91315991525, "top": 197343.4997165215, "right": 591007.91315991525, "bottom": 196343.4997165215, "id": 16 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 590007.913714013411663, 197343.500863675028086 ], [ 591007.91371489490848, 197343.500863418536028 ], [ 591007.913715152069926, 196343.50086301297415 ], [ 590007.913714269758202, 196343.500863268069224 ], [ 590007.913714013411663, 197343.500863675028086 ] ] ] } },
    { "type": "Feature", "properties": { "left": 591007.91315991525, "top": 200343.4997165215, "right": 592007.91315991525, "bottom": 199343.4997165215, "id": 21 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 591007.913714122259989, 200343.500864623201778 ], [ 592007.913715002010576, 200343.500864362576976 ], [ 592007.913715260336176, 199343.500863966677571 ], [ 591007.913714380003512, 199343.500864221656229 ], [ 591007.913714122259989, 200343.500864623201778 ] ] ] } },
    { "type": "Feature", "properties": { "left": 591007.91315991525, "top": 199343.4997165215, "right": 592007.91315991525, "bottom": 198343.4997165215, "id": 22 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 591007.913714380003512, 199343.500864221656229 ], [ 592007.913715260336176, 199343.500863966677571 ], [ 592007.913715518196113, 198343.500863563938765 ], [ 591007.913714637747034, 198343.500863822497195 ], [ 591007.913714380003512, 199343.500864221656229 ] ] ] } },
    { "type": "Feature", "properties": { "left": 591007.91315991525, "top": 198343.4997165215, "right": 592007.91315991525, "bottom": 197343.4997165215, "id": 23 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 591007.913714637747034, 198343.500863822497195 ], [ 592007.913715518196113, 198343.500863563938765 ], [ 592007.913715775939636, 197343.500863163499162 ], [ 591007.91371489490848, 197343.500863418536028 ], [ 591007.913714637747034, 198343.500863822497195 ] ] ] } },
    { "type": "Feature", "properties": { "left": 591007.91315991525, "top": 197343.4997165215, "right": 592007.91315991525, "bottom": 196343.4997165215, "id": 24 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 591007.91371489490848, 197343.500863418536028 ], [ 592007.913715775939636, 197343.500863163499162 ], [ 592007.913716033799574, 196343.500862755754497 ], [ 591007.913715152069926, 196343.50086301297415 ], [ 591007.91371489490848, 197343.500863418536028 ] ] ] } },
    { "type": "Feature", "properties": { "left": 592007.91315991525, "top": 200343.4997165215, "right": 593007.91315991525, "bottom": 199343.4997165215, "id": 29 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 592007.913715002010576, 200343.500864362576976 ], [ 593007.913715881295502, 200343.500864109082613 ], [ 593007.913716139853932, 199343.50086370180361 ], [ 592007.913715260336176, 199343.500863966677571 ], [ 592007.913715002010576, 200343.500864362576976 ] ] ] } },
    { "type": "Feature", "properties": { "left": 592007.91315991525, "top": 199343.4997165215, "right": 593007.91315991525, "bottom": 198343.4997165215, "id": 30 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 592007.913715260336176, 199343.500863966677571 ], [ 593007.913716139853932, 199343.50086370180361 ], [ 593007.913716398179531, 198343.500863301829668 ], [ 592007.913715518196113, 198343.500863563938765 ], [ 592007.913715260336176, 199343.500863966677571 ] ] ] } },
    { "type": "Feature", "properties": { "left": 592007.91315991525, "top": 198343.4997165215, "right": 593007.91315991525, "bottom": 197343.4997165215, "id": 31 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 592007.913715518196113, 198343.500863563938765 ], [ 593007.913716398179531, 198343.500863301829668 ], [ 593007.9137166562723, 197343.500862904184032 ], [ 592007.913715775939636, 197343.500863163499162 ], [ 592007.913715518196113, 198343.500863563938765 ] ] ] } },
    { "type": "Feature", "properties": { "left": 592007.91315991525, "top": 197343.4997165215, "right": 593007.91315991525, "bottom": 196343.4997165215, "id": 32 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 592007.913715775939636, 197343.500863163499162 ], [ 593007.9137166562723, 197343.500862904184032 ], [ 593007.913716914597899, 196343.500862502085511 ], [ 592007.913716033799574, 196343.500862755754497 ], [ 592007.913715775939636, 197343.500863163499162 ] ] ] } },
    { "type": "Feature", "properties": { "left": 593007.91315991525, "top": 200343.4997165215, "right": 594007.91315991525, "bottom": 199343.4997165215, "id": 37 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 593007.913715881295502, 200343.500864109082613 ], [ 594007.913716759416275, 200343.50086384563474 ], [ 594007.913717018440366, 199343.500863445398863 ], [ 593007.913716139853932, 199343.50086370180361 ], [ 593007.913715881295502, 200343.500864109082613 ] ] ] } },
    { "type": "Feature", "properties": { "left": 593007.91315991525, "top": 199343.4997165215, "right": 594007.91315991525, "bottom": 198343.4997165215, "id": 38 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 593007.913716139853932, 199343.50086370180361 ], [ 594007.913717018440366, 199343.500863445398863 ], [ 594007.913717277348042, 198343.500863048247993 ], [ 593007.913716398179531, 198343.500863301829668 ], [ 593007.913716139853932, 199343.50086370180361 ] ] ] } },
    { "type": "Feature", "properties": { "left": 593007.91315991525, "top": 198343.4997165215, "right": 594007.91315991525, "bottom": 197343.4997165215, "id": 39 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 593007.913716398179531, 198343.500863301829668 ], [ 594007.913717277348042, 198343.500863048247993 ], [ 594007.913717536255717, 197343.500862644868903 ], [ 593007.9137166562723, 197343.500862904184032 ], [ 593007.913716398179531, 198343.500863301829668 ] ] ] } },
    { "type": "Feature", "properties": { "left": 593007.91315991525, "top": 197343.4997165215, "right": 594007.91315991525, "bottom": 196343.4997165215, "id": 40 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 593007.9137166562723, 197343.500862904184032 ], [ 594007.913717536255717, 197343.500862644868903 ], [ 594007.913717794581316, 196343.50086224131519 ], [ 593007.913716914597899, 196343.500862502085511 ], [ 593007.9137166562723, 197343.500862904184032 ] ] ] } },
    { "type": "Feature", "properties": { "left": 594007.91315991525, "top": 201343.4997165215, "right": 595007.91315991525, "bottom": 200343.4997165215, "id": 44 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 594007.913716500042938, 201343.500864244444529 ], [ 595007.913717377348803, 201343.500863989553181 ], [ 595007.913717636954971, 200343.500863589288201 ], [ 594007.913716759416275, 200343.50086384563474 ], [ 594007.913716500042938, 201343.500864244444529 ] ] ] } },
    { "type": "Feature", "properties": { "left": 594007.91315991525, "top": 200343.4997165215, "right": 595007.91315991525, "bottom": 199343.4997165215, "id": 45 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 594007.913716759416275, 200343.50086384563474 ], [ 595007.913717636954971, 200343.500863589288201 ], [ 595007.913717896677554, 199343.500863189023221 ], [ 594007.913717018440366, 199343.500863445398863 ], [ 594007.913716759416275, 200343.50086384563474 ] ] ] } },
    { "type": "Feature", "properties": { "left": 594007.91315991525, "top": 199343.4997165215, "right": 595007.91315991525, "bottom": 198343.4997165215, "id": 46 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 594007.913717018440366, 199343.500863445398863 ], [ 595007.913717896677554, 199343.500863189023221 ], [ 595007.913718155934475, 198343.500862786138896 ], [ 594007.913717277348042, 198343.500863048247993 ], [ 594007.913717018440366, 199343.500863445398863 ] ] ] } },
    { "type": "Feature", "properties": { "left": 594007.91315991525, "top": 198343.4997165215, "right": 595007.91315991525, "bottom": 197343.4997165215, "id": 47 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 594007.913717277348042, 198343.500863048247993 ], [ 595007.913718155934475, 198343.500862786138896 ], [ 595007.913718415191397, 197343.500862388405949 ], [ 594007.913717536255717, 197343.500862644868903 ], [ 594007.913717277348042, 198343.500863048247993 ] ] ] } },
    { "type": "Feature", "properties": { "left": 595007.91315991525, "top": 202343.4997165215, "right": 596007.91315991525, "bottom": 201343.4997165215, "id": 51 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 595007.913717117393389, 202343.500864383589942 ], [ 596007.913717993302271, 202343.500864124478539 ], [ 596007.913718253956176, 201343.500863726163516 ], [ 595007.913717377348803, 201343.500863989553181 ], [ 595007.913717117393389, 202343.500864383589942 ] ] ] } },
    { "type": "Feature", "properties": { "left": 595007.91315991525, "top": 201343.4997165215, "right": 596007.91315991525, "bottom": 200343.4997165215, "id": 52 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 595007.913717377348803, 201343.500863989553181 ], [ 596007.913718253956176, 201343.500863726163516 ], [ 596007.91371851391159, 200343.500863331515575 ], [ 595007.913717636954971, 200343.500863589288201 ], [ 595007.913717377348803, 201343.500863989553181 ] ] ] } },
    { "type": "Feature", "properties": { "left": 595007.91315991525, "top": 200343.4997165215, "right": 596007.91315991525, "bottom": 199343.4997165215, "id": 53 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 595007.913717636954971, 200343.500863589288201 ], [ 596007.91371851391159, 200343.500863331515575 ], [ 596007.91371877421625, 199343.500862928369315 ], [ 595007.913717896677554, 199343.500863189023221 ], [ 595007.913717636954971, 200343.500863589288201 ] ] ] } },
    { "type": "Feature", "properties": { "left": 595007.91315991525, "top": 199343.4997165215, "right": 596007.91315991525, "bottom": 198343.4997165215, "id": 54 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 595007.913717896677554, 199343.500863189023221 ], [ 596007.91371877421625, 199343.500862928369315 ], [ 596007.913719034055248, 198343.500862526881974 ], [ 595007.913718155934475, 198343.500862786138896 ], [ 595007.913717896677554, 199343.500863189023221 ] ] ] } },
    { "type": "Feature", "properties": { "left": 595007.91315991525, "top": 198343.4997165215, "right": 596007.91315991525, "bottom": 197343.4997165215, "id": 55 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 595007.913718155934475, 198343.500862786138896 ], [ 596007.913719034055248, 198343.500862526881974 ], [ 596007.91371929331217, 197343.500862125569256 ], [ 595007.913718415191397, 197343.500862388405949 ], [ 595007.913718155934475, 198343.500862786138896 ] ] ] } },
    { "type": "Feature", "properties": { "left": 596007.91315991525, "top": 202343.4997165215, "right": 597007.91315991525, "bottom": 201343.4997165215, "id": 59 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 596007.913717993302271, 202343.500864124478539 ], [ 597007.913718868861906, 202343.500863862544065 ], [ 597007.913719129515812, 201343.500863464170834 ], [ 596007.913718253956176, 201343.500863726163516 ], [ 596007.913717993302271, 202343.500864124478539 ] ] ] } },
    { "type": "Feature", "properties": { "left": 596007.91315991525, "top": 201343.4997165215, "right": 597007.91315991525, "bottom": 200343.4997165215, "id": 60 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 596007.913718253956176, 201343.500863726163516 ], [ 597007.913719129515812, 201343.500863464170834 ], [ 597007.913719390169717, 200343.500863072345965 ], [ 596007.91371851391159, 200343.500863331515575 ], [ 596007.913718253956176, 201343.500863726163516 ] ] ] } },
    { "type": "Feature", "properties": { "left": 596007.91315991525, "top": 200343.4997165215, "right": 597007.91315991525, "bottom": 199343.4997165215, "id": 61 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 596007.91371851391159, 200343.500863331515575 ], [ 597007.913719390169717, 200343.500863072345965 ], [ 597007.913719650823623, 199343.500862669141497 ], [ 596007.91371877421625, 199343.500862928369315 ], [ 596007.91371851391159, 200343.500863331515575 ] ] ] } },
    { "type": "Feature", "properties": { "left": 596007.91315991525, "top": 199343.4997165215, "right": 597007.91315991525, "bottom": 198343.4997165215, "id": 62 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 596007.91371877421625, 199343.500862928369315 ], [ 597007.913719650823623, 199343.500862669141497 ], [ 597007.913719910895452, 198343.500862266198965 ], [ 596007.913719034055248, 198343.500862526881974 ], [ 596007.91371877421625, 199343.500862928369315 ] ] ] } },
    { "type": "Feature", "properties": { "left": 596007.91315991525, "top": 198343.4997165215, "right": 597007.91315991525, "bottom": 197343.4997165215, "id": 63 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 596007.913719034055248, 198343.500862526881974 ], [ 597007.913719910895452, 198343.500862266198965 ], [ 597007.913720170967281, 197343.500861869077198 ], [ 596007.91371929331217, 197343.500862125569256 ], [ 596007.913719034055248, 198343.500862526881974 ] ] ] } },
    { "type": "Feature", "properties": { "left": 596007.91315991525, "top": 197343.4997165215, "right": 597007.91315991525, "bottom": 196343.4997165215, "id": 64 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 596007.91371929331217, 197343.500862125569256 ], [ 597007.913720170967281, 197343.500861869077198 ], [ 597007.913720430922695, 196343.500861466833157 ], [ 596007.913719552918337, 196343.500861725449795 ], [ 596007.91371929331217, 197343.500862125569256 ] ] ] } },
    { "type": "Feature", "properties": { "left": 597007.91315991525, "top": 202343.4997165215, "right": 598007.91315991525, "bottom": 201343.4997165215, "id": 67 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 597007.913718868861906, 202343.500863862544065 ], [ 598007.913719743257388, 202343.50086360485875 ], [ 598007.913720004493371, 201343.500863205030328 ], [ 597007.913719129515812, 201343.500863464170834 ], [ 597007.913718868861906, 202343.500863862544065 ] ] ] } },
    { "type": "Feature", "properties": { "left": 597007.91315991525, "top": 201343.4997165215, "right": 598007.91315991525, "bottom": 200343.4997165215, "id": 68 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 597007.913719129515812, 201343.500863464170834 ], [ 598007.913720004493371, 201343.500863205030328 ], [ 598007.913720265729353, 200343.500862810324179 ], [ 597007.913719390169717, 200343.500863072345965 ], [ 597007.913719129515812, 201343.500863464170834 ] ] ] } },
    { "type": "Feature", "properties": { "left": 597007.91315991525, "top": 200343.4997165215, "right": 598007.91315991525, "bottom": 199343.4997165215, "id": 69 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 597007.913719390169717, 200343.500863072345965 ], [ 598007.913720265729353, 200343.500862810324179 ], [ 598007.913720526732504, 199343.50086240991368 ], [ 597007.913719650823623, 199343.500862669141497 ], [ 597007.913719390169717, 200343.500863072345965 ] ] ] } },
    { "type": "Feature", "properties": { "left": 597007.91315991525, "top": 199343.4997165215, "right": 598007.91315991525, "bottom": 198343.4997165215, "id": 70 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 597007.913719650823623, 199343.500862669141497 ], [ 598007.913720526732504, 199343.50086240991368 ], [ 598007.913720787153579, 198343.500862005515955 ], [ 597007.913719910895452, 198343.500862266198965 ], [ 597007.913719650823623, 199343.500862669141497 ] ] ] } },
    { "type": "Feature", "properties": { "left": 597007.91315991525, "top": 198343.4997165215, "right": 598007.91315991525, "bottom": 197343.4997165215, "id": 71 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 597007.913719910895452, 198343.500862266198965 ], [ 598007.913720787153579, 198343.500862005515955 ], [ 598007.9137210479239, 197343.500861606938997 ], [ 597007.913720170967281, 197343.500861869077198 ], [ 597007.913719910895452, 198343.500862266198965 ] ] ] } },
    { "type": "Feature", "properties": { "left": 597007.91315991525, "top": 197343.4997165215, "right": 598007.91315991525, "bottom": 196343.4997165215, "id": 72 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 597007.913720170967281, 197343.500861869077198 ], [ 598007.9137210479239, 197343.500861606938997 ], [ 598007.91372130846139, 196343.500861208885908 ], [ 597007.913720430922695, 196343.500861466833157 ], [ 597007.913720170967281, 197343.500861869077198 ] ] ] } },
    { "type": "Feature", "properties": { "left": 598007.91315991525, "top": 203343.4997165215, "right": 599007.91315991525, "bottom": 202343.4997165215, "id": 74 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 598007.91371948167216, 203343.500863997556735 ], [ 599007.913720355252735, 203343.500863734254381 ], [ 599007.913720617303625, 202343.500863340072101 ], [ 598007.913719743257388, 202343.50086360485875 ], [ 598007.91371948167216, 203343.500863997556735 ] ] ] } },
    { "type": "Feature", "properties": { "left": 598007.91315991525, "top": 202343.4997165215, "right": 599007.91315991525, "bottom": 201343.4997165215, "id": 75 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 598007.913719743257388, 202343.50086360485875 ], [ 599007.913720617303625, 202343.500863340072101 ], [ 599007.913720878888853, 201343.500862941640662 ], [ 598007.913720004493371, 201343.500863205030328 ], [ 598007.913719743257388, 202343.50086360485875 ] ] ] } },
    { "type": "Feature", "properties": { "left": 598007.91315991525, "top": 201343.4997165215, "right": 599007.91315991525, "bottom": 200343.4997165215, "id": 76 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 598007.913720004493371, 201343.500863205030328 ], [ 599007.913720878888853, 201343.500862941640662 ], [ 599007.913721140706912, 200343.500862545479322 ], [ 598007.913720265729353, 200343.500862810324179 ], [ 598007.913720004493371, 201343.500863205030328 ] ] ] } },
    { "type": "Feature", "properties": { "left": 598007.91315991525, "top": 200343.4997165215, "right": 599007.91315991525, "bottom": 199343.4997165215, "id": 77 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 598007.913720265729353, 200343.500862810324179 ], [ 599007.913721140706912, 200343.500862545479322 ], [ 599007.913721401942894, 199343.500862145039719 ], [ 598007.913720526732504, 199343.50086240991368 ], [ 598007.913720265729353, 200343.500862810324179 ] ] ] } },
    { "type": "Feature", "properties": { "left": 598007.91315991525, "top": 199343.4997165215, "right": 599007.91315991525, "bottom": 198343.4997165215, "id": 78 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 598007.913720526732504, 199343.50086240991368 ], [ 599007.913721401942894, 199343.500862145039719 ], [ 599007.913721663062461, 198343.500861744832946 ], [ 598007.913720787153579, 198343.500862005515955 ], [ 598007.913720526732504, 199343.50086240991368 ] ] ] } },
    { "type": "Feature", "properties": { "left": 598007.91315991525, "top": 198343.4997165215, "right": 599007.91315991525, "bottom": 197343.4997165215, "id": 79 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 598007.913720787153579, 198343.500862005515955 ], [ 599007.913721663062461, 198343.500861744832946 ], [ 599007.913721924065612, 197343.500861346226884 ], [ 598007.9137210479239, 197343.500861606938997 ], [ 598007.913720787153579, 198343.500862005515955 ] ] ] } },
    { "type": "Feature", "properties": { "left": 599007.91315991525, "top": 203343.4997165215, "right": 600007.91315991525, "bottom": 202343.4997165215, "id": 82 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 599007.913720355252735, 203343.500863734254381 ], [ 600007.913721228134818, 203343.500863469525939 ], [ 600007.913721490418538, 202343.500863079563715 ], [ 599007.913720617303625, 202343.500863340072101 ], [ 599007.913720355252735, 203343.500863734254381 ] ] ] } },
    { "type": "Feature", "properties": { "left": 599007.91315991525, "top": 202343.4997165215, "right": 600007.91315991525, "bottom": 201343.4997165215, "id": 83 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 599007.913720617303625, 202343.500863340072101 ], [ 600007.913721490418538, 202343.500863079563715 ], [ 600007.913721752585843, 201343.500862681074068 ], [ 599007.913720878888853, 201343.500862941640662 ], [ 599007.913720617303625, 202343.500863340072101 ] ] ] } },
    { "type": "Feature", "properties": { "left": 599007.91315991525, "top": 201343.4997165215, "right": 600007.91315991525, "bottom": 200343.4997165215, "id": 84 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 599007.913720878888853, 201343.500862941640662 ], [ 600007.913721752585843, 201343.500862681074068 ], [ 600007.913722014985979, 200343.500862282031449 ], [ 599007.913721140706912, 200343.500862545479322 ], [ 599007.913720878888853, 201343.500862941640662 ] ] ] } },
    { "type": "Feature", "properties": { "left": 599007.91315991525, "top": 200343.4997165215, "right": 600007.91315991525, "bottom": 199343.4997165215, "id": 85 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 599007.913721140706912, 200343.500862545479322 ], [ 600007.913722014985979, 200343.500862282031449 ], [ 600007.913722276804037, 199343.500861887237988 ], [ 599007.913721401942894, 199343.500862145039719 ], [ 599007.913721140706912, 200343.500862545479322 ] ] ] } },
    { "type": "Feature", "properties": { "left": 599007.91315991525, "top": 199343.4997165215, "right": 600007.91315991525, "bottom": 198343.4997165215, "id": 86 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 599007.913721401942894, 199343.500862145039719 ], [ 600007.913722276804037, 199343.500861887237988 ], [ 600007.913722538156435, 198343.500861484149937 ], [ 599007.913721663062461, 198343.500861744832946 ], [ 599007.913721401942894, 199343.500862145039719 ] ] ] } },
    { "type": "Feature", "properties": { "left": 599007.91315991525, "top": 198343.4997165215, "right": 600007.91315991525, "bottom": 197343.4997165215, "id": 87 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 599007.913721663062461, 198343.500861744832946 ], [ 600007.913722538156435, 198343.500861484149937 ], [ 600007.913722799508832, 197343.500861084088683 ], [ 599007.913721924065612, 197343.500861346226884 ], [ 599007.913721663062461, 198343.500861744832946 ] ] ] } },
    { "type": "Feature", "properties": { "left": 600007.91315991525, "top": 204343.4997165215, "right": 601007.91315991525, "bottom": 203343.4997165215, "id": 89 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 600007.913720965385437, 204343.5008638688887 ], [ 601007.913721837103367, 204343.500863598543219 ], [ 601007.913722100201994, 203343.500863207620569 ], [ 600007.913721228134818, 203343.500863469525939 ], [ 600007.913720965385437, 204343.5008638688887 ] ] ] } },
    { "type": "Feature", "properties": { "left": 600007.91315991525, "top": 203343.4997165215, "right": 601007.91315991525, "bottom": 202343.4997165215, "id": 90 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 600007.913721228134818, 203343.500863469525939 ], [ 601007.913722100201994, 203343.500863207620569 ], [ 601007.913722362951376, 202343.500862811983097 ], [ 600007.913721490418538, 202343.500863079563715 ], [ 600007.913721228134818, 203343.500863469525939 ] ] ] } },
    { "type": "Feature", "properties": { "left": 600007.91315991525, "top": 202343.4997165215, "right": 601007.91315991525, "bottom": 201343.4997165215, "id": 91 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 600007.913721490418538, 202343.500863079563715 ], [ 601007.913722362951376, 202343.500862811983097 ], [ 601007.913722625817172, 201343.500862420536578 ], [ 600007.913721752585843, 201343.500862681074068 ], [ 600007.913721490418538, 202343.500863079563715 ] ] ] } },
    { "type": "Feature", "properties": { "left": 600007.91315991525, "top": 201343.4997165215, "right": 601007.91315991525, "bottom": 200343.4997165215, "id": 92 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 600007.913721752585843, 201343.500862681074068 ], [ 601007.913722625817172, 201343.500862420536578 ], [ 601007.913722888100892, 200343.500862021435751 ], [ 600007.913722014985979, 200343.500862282031449 ], [ 600007.913721752585843, 201343.500862681074068 ] ] ] } },
    { "type": "Feature", "properties": { "left": 600007.91315991525, "top": 200343.4997165215, "right": 601007.91315991525, "bottom": 199343.4997165215, "id": 93 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 600007.913722014985979, 200343.500862282031449 ], [ 601007.913722888100892, 200343.500862021435751 ], [ 601007.913723150501028, 199343.500861620908836 ], [ 600007.913722276804037, 199343.500861887237988 ], [ 600007.913722014985979, 200343.500862282031449 ] ] ] } },
    { "type": "Feature", "properties": { "left": 600007.91315991525, "top": 199343.4997165215, "right": 601007.91315991525, "bottom": 198343.4997165215, "id": 94 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 600007.913722276804037, 199343.500861887237988 ], [ 601007.913723150501028, 199343.500861620908836 ], [ 601007.913723412551917, 198343.500861227716086 ], [ 600007.913722538156435, 198343.500861484149937 ], [ 600007.913722276804037, 199343.500861887237988 ] ] ] } },
    { "type": "Feature", "properties": { "left": 600007.91315991525, "top": 198343.4997165215, "right": 601007.91315991525, "bottom": 197343.4997165215, "id": 95 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 600007.913722538156435, 198343.500861484149937 ], [ 601007.913723412551917, 198343.500861227716086 ], [ 601007.913723674486391, 197343.500860817701323 ], [ 600007.913722799508832, 197343.500861084088683 ], [ 600007.913722538156435, 198343.500861484149937 ] ] ] } },
    { "type": "Feature", "properties": { "left": 601007.91315991525, "top": 204343.4997165215, "right": 602007.91315991525, "bottom": 203343.4997165215, "id": 97 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 601007.913721837103367, 204343.500863598543219 ], [ 602007.913722707773559, 204343.500863340916112 ], [ 602007.913722971570678, 203343.500862944318214 ], [ 601007.913722100201994, 203343.500863207620569 ], [ 601007.913721837103367, 204343.500863598543219 ] ] ] } },
    { "type": "Feature", "properties": { "left": 601007.91315991525, "top": 203343.4997165215, "right": 602007.91315991525, "bottom": 202343.4997165215, "id": 98 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 601007.913722100201994, 203343.500863207620569 ], [ 602007.913722971570678, 203343.500862944318214 ], [ 602007.913723234902136, 202343.500862552871695 ], [ 601007.913722362951376, 202343.500862811983097 ], [ 601007.913722100201994, 203343.500863207620569 ] ] ] } },
    { "type": "Feature", "properties": { "left": 601007.91315991525, "top": 202343.4997165215, "right": 602007.91315991525, "bottom": 201343.4997165215, "id": 99 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 601007.913722362951376, 202343.500862811983097 ], [ 602007.913723234902136, 202343.500862552871695 ], [ 602007.913723498117179, 201343.500862154294737 ], [ 601007.913722625817172, 201343.500862420536578 ], [ 601007.913722362951376, 202343.500862811983097 ] ] ] } },
    { "type": "Feature", "properties": { "left": 601007.91315991525, "top": 201343.4997165215, "right": 602007.91315991525, "bottom": 200343.4997165215, "id": 100 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 601007.913722625817172, 201343.500862420536578 ], [ 602007.913723498117179, 201343.500862154294737 ], [ 602007.913723760750145, 200343.500861759413965 ], [ 601007.913722888100892, 200343.500862021435751 ], [ 601007.913722625817172, 201343.500862420536578 ] ] ] } },
    { "type": "Feature", "properties": { "left": 601007.91315991525, "top": 200343.4997165215, "right": 602007.91315991525, "bottom": 199343.4997165215, "id": 101 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 601007.913722888100892, 200343.500862021435751 ], [ 602007.913723760750145, 200343.500861759413965 ], [ 602007.913724023732357, 199343.500861357431859 ], [ 601007.913723150501028, 199343.500861620908836 ], [ 601007.913722888100892, 200343.500862021435751 ] ] ] } },
    { "type": "Feature", "properties": { "left": 601007.91315991525, "top": 199343.4997165215, "right": 602007.91315991525, "bottom": 198343.4997165215, "id": 102 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 601007.913723150501028, 199343.500861620908836 ], [ 602007.913724023732357, 199343.500861357431859 ], [ 602007.913724286132492, 198343.500860958534759 ], [ 601007.913723412551917, 198343.500861227716086 ], [ 601007.913723150501028, 199343.500861620908836 ] ] ] } },
    { "type": "Feature", "properties": { "left": 601007.91315991525, "top": 198343.4997165215, "right": 602007.91315991525, "bottom": 197343.4997165215, "id": 103 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 601007.913723412551917, 198343.500861227716086 ], [ 602007.913724286132492, 198343.500860958534759 ], [ 602007.913724548416212, 197343.500860561209265 ], [ 601007.913723674486391, 197343.500860817701323 ], [ 601007.913723412551917, 198343.500861227716086 ] ] ] } },
    { "type": "Feature", "properties": { "left": 602007.91315991525, "top": 203343.4997165215, "right": 603007.91315991525, "bottom": 202343.4997165215, "id": 106 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 602007.913722971570678, 203343.500862944318214 ], [ 603007.913723842357285, 203343.50086268101586 ], [ 603007.913724106037989, 202343.500862290937221 ], [ 602007.913723234902136, 202343.500862552871695 ], [ 602007.913722971570678, 203343.500862944318214 ] ] ] } },
    { "type": "Feature", "properties": { "left": 602007.91315991525, "top": 202343.4997165215, "right": 603007.91315991525, "bottom": 201343.4997165215, "id": 107 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 602007.913723234902136, 202343.500862552871695 ], [ 603007.913724106037989, 202343.500862290937221 ], [ 603007.913724369718693, 201343.500861890905071 ], [ 602007.913723498117179, 201343.500862154294737 ], [ 602007.913723234902136, 202343.500862552871695 ] ] ] } },
    { "type": "Feature", "properties": { "left": 602007.91315991525, "top": 201343.4997165215, "right": 603007.91315991525, "bottom": 200343.4997165215, "id": 108 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 602007.913723498117179, 201343.500862154294737 ], [ 603007.913724369718693, 201343.500861890905071 ], [ 603007.913724632817321, 200343.500861490319949 ], [ 602007.913723760750145, 200343.500861759413965 ], [ 602007.913723498117179, 201343.500862154294737 ] ] ] } },
    { "type": "Feature", "properties": { "left": 602007.91315991525, "top": 200343.4997165215, "right": 603007.91315991525, "bottom": 199343.4997165215, "id": 109 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 602007.913723760750145, 200343.500861759413965 ], [ 603007.913724632817321, 200343.500861490319949 ], [ 603007.913724896148778, 199343.500861099630129 ], [ 602007.913724023732357, 199343.500861357431859 ], [ 602007.913723760750145, 200343.500861759413965 ] ] ] } },
    { "type": "Feature", "properties": { "left": 602007.91315991525, "top": 199343.4997165215, "right": 603007.91315991525, "bottom": 198343.4997165215, "id": 110 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 602007.913724023732357, 199343.500861357431859 ], [ 603007.913724896148778, 199343.500861099630129 ], [ 603007.913725159130991, 198343.500860699976329 ], [ 602007.913724286132492, 198343.500860958534759 ], [ 602007.913724023732357, 199343.500861357431859 ] ] ] } },
    { "type": "Feature", "properties": { "left": 602007.91315991525, "top": 198343.4997165215, "right": 603007.91315991525, "bottom": 197343.4997165215, "id": 111 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 602007.913724286132492, 198343.500860958534759 ], [ 603007.913725159130991, 198343.500860699976329 ], [ 603007.913725421996787, 197343.500860298372572 ], [ 602007.913724548416212, 197343.500860561209265 ], [ 602007.913724286132492, 198343.500860958534759 ] ] ] } },
    { "type": "Feature", "properties": { "left": 602007.91315991525, "top": 197343.4997165215, "right": 603007.91315991525, "bottom": 196343.4997165215, "id": 112 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 602007.913724548416212, 197343.500860561209265 ], [ 603007.913725421996787, 197343.500860298372572 ], [ 603007.913725684513338, 196343.500859897292685 ], [ 602007.913724810467102, 196343.500860156636918 ], [ 602007.913724548416212, 197343.500860561209265 ] ] ] } },
    { "type": "Feature", "properties": { "left": 603007.91315991525, "top": 202343.4997165215, "right": 604007.91315991525, "bottom": 201343.4997165215, "id": 115 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 603007.913724106037989, 202343.500862290937221 ], [ 604007.913724976475351, 202343.500862021930516 ], [ 604007.9137252405053, 201343.500861633190652 ], [ 603007.913724369718693, 201343.500861890905071 ], [ 603007.913724106037989, 202343.500862290937221 ] ] ] } },
    { "type": "Feature", "properties": { "left": 603007.91315991525, "top": 201343.4997165215, "right": 604007.91315991525, "bottom": 200343.4997165215, "id": 116 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 603007.913724369718693, 201343.500861890905071 ], [ 604007.9137252405053, 201343.500861633190652 ], [ 604007.91372550430242, 200343.500861235399498 ], [ 603007.913724632817321, 200343.500861490319949 ], [ 603007.913724369718693, 201343.500861890905071 ] ] ] } },
    { "type": "Feature", "properties": { "left": 603007.91315991525, "top": 200343.4997165215, "right": 604007.91315991525, "bottom": 199343.4997165215, "id": 117 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 603007.913724632817321, 200343.500861490319949 ], [ 604007.91372550430242, 200343.500861235399498 ], [ 604007.913725768099539, 199343.500860832631588 ], [ 603007.913724896148778, 199343.500861099630129 ], [ 603007.913724632817321, 200343.500861490319949 ] ] ] } },
    { "type": "Feature", "properties": { "left": 603007.91315991525, "top": 199343.4997165215, "right": 604007.91315991525, "bottom": 198343.4997165215, "id": 118 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 603007.913724896148778, 199343.500861099630129 ], [ 604007.913725768099539, 199343.500860832631588 ], [ 604007.913726031430997, 198343.500860437867232 ], [ 603007.913725159130991, 198343.500860699976329 ], [ 603007.913724896148778, 199343.500861099630129 ] ] ] } },
    { "type": "Feature", "properties": { "left": 604007.91315991525, "top": 199343.4997165215, "right": 605007.91315991525, "bottom": 198343.4997165215, "id": 126 }, "geometry": { "type": "Polygon", "coordinates": [ [ [ 604007.913725768099539, 199343.500860832631588 ], [ 605007.913726639118977, 199343.500860570551595 ], [ 605007.913726902916096, 198343.500860170111991 ], [ 604007.913726031430997, 198343.500860437867232 ], [ 604007.913725768099539, 199343.500860832631588 ] ] ] } }
    ]
    }
    