import React from 'react'

function WithUsCnt() {
    return (
        <div className="tbsContainer p-4">
            <div className="row">
                <div className="col-md-8" style={{marginLeft: '12px'}}>
                    <h2>Mitmachen</h2>
                    <br />
                    <p>2015&nbsp;war das letzte Feldjahr der FLIB-Kartierung. Die Citizen-Science-Phase des Projekts ist nun&nbsp;abgeschlossen.&nbsp;</p>
                    <p>Aktuell (2016) laufen die gezielten Nachkontrollen und Nachkartierungen durch SpezialistInnen.</p>
                    <p>&nbsp;</p>
                </div>
                <div className="col-lg-4" style={{marginLeft: '-12px'}}>
                    <p align="center">
                        <br /><br />&nbsp;<br />
                        <a data-fancybox data-caption="gelb: frei  |  dunkelblau: Pilot 2012  |  hellblau: 2013  |  rot: 2014" href="assets/img/map_quadrate.jpg" rel="mitmachen">&nbsp; </a>
                    </p>
                </div>
            </div>
        </div>				
    )
}

export default WithUsCnt
