import { ADD_ARTICLE } from "../constants/action-types";

const initialState = {
  articles: [],
  remoteArticles: [],
  remoteTaxon: [],
  taxonDefaultYear: []
};

function rootReducer(state = initialState, action) {
  if (action.type === ADD_ARTICLE) {
    return Object.assign({}, state, {
      articles: state.articles.concat(action.payload)
    });
  }

  if (action.type === "DATA_LOADED") {
    //console.log("Reducer", state.remoteArticles);
    return Object.assign({}, state, {
      remoteArticles: state.articles.concat(action.payload)
    });
  }
  if (action.type === "TAXON_LOADED") {
    return Object.assign({}, state, {
      remoteTaxon: state.articles.concat(action.payload)
    });
  }
  if (action.type === "SET_ACTUELYEAR") {
    return Object.assign({}, state, {
      taxonDefaultYear: action.payload
    });
  } 

  if(action.type === "FOUND_BAD_WORD"){
    return;
  }

  return state;
}

export default rootReducer;