import React from 'react'
import KartierImg from '../assets/img/Kartieranleitung.jpg'

function MaterialsCnt() {
    return (
        <div className="tbsContainer p-4">
            <div className="row">
                <hr className="visible-xs" />
                <div className="col-md-8">
                    <h2>Unterlagen</h2>
                    <br />
                    <h3>Anleitungen</h3>
                    <ul className="list-group">
                        <li className="list-group-item"><a className="download_pdf" href="assets/downloads/Kartieranleitung_FliB_130317.pdf" target="_blank">Kartieranleitung FLIB</a></li>
                        <li className="list-group-item"><a className="download_pdf" href="assets/downloads/F1_Artenliste_130204.pdf" target="_blank">Aufnahmeblatt (Formular F1)</a></li>
                        <li className="list-group-item"><a className="download_pdf" href="assets/downloads/F2_Fundortliste_130204.pdf" target="_blank">Fundortliste (Formular F2)</a></li>
                    </ul>
                    <br />
                    <h3>Artenlisten</h3>
                    <ul className="list-group">
                        <li className="list-group-item"><a className="download_pdf" href="assets/downloads/FLIB_Gesamtartenliste_140303.pdf" target="_blank">Gesamtartenliste FLIB (Stand 3.3.2014)</a></li>
                        <li className="list-group-item"><a className="download_pdf" href="assets/downloads/FLIB_Exoten_140303.pdf" target="_blank">Liste Exoten FLIB (Stand 3.3.2014)</a></li>
                    </ul>
                </div>
                <div className="col-lg-4">
                <p align="center">
                    <br /><br />
                    <a data-fancybox data-caption="Download auf der linken Seite" href={KartierImg} rel="unterlagen"> 
                        <img className="img img-rounded" src={KartierImg} alt="Kartieranleitung - Download auf der linken Seite" width="95%" /> 
                    </a>
                </p>
                </div>
            </div>
        </div>
    )
}

export default MaterialsCnt
