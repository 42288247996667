import React from 'react'
import { Tabs, Tab } from 'react-bootstrap'
import ProjectCnt from './ProjectCnt'
import OrganisationCnt from './OrganisationCnt'
import WithUsCnt from './WithUsCnt'
import MaterialsCnt from './MaterialsCnt'

function FlibProjektCnt() {
    return (
        <div className="colonne recherche">
		<h2>FLIB -> Floreninventar der Stadt Bern</h2>
        <Tabs defaultActiveKey="projekt" id="flibTab">
            <Tab eventKey="projekt" title="Das Projekt">
               <ProjectCnt />
            </Tab>
            <Tab eventKey="organisation" title="Organisation">
               <OrganisationCnt />
            </Tab>
            <Tab eventKey="mitmachen" title="Mitmachen">
               <WithUsCnt />
            </Tab>
            <Tab eventKey="unterlagen" title="Unterlagen">
               <MaterialsCnt />
            </Tab>
        </Tabs>
	    </div>
    )
}

export default FlibProjektCnt
