import React, { Component } from 'react'
import ReactDOM from 'react-dom'

import $ from 'jquery';
import 'datatables.net';
//import 'datatables.net-buttons-dt';
//import 'datatables.net-searchpanes-dt';
import 'bootstrap'
import 'datatables.net-bs/js/dataTables.bootstrap';
import 'datatables.net-bs/css/dataTables.bootstrap.css';
import TblLanguage from '../../src/assets/json/datatables/German.json';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { getTaxon } from "../api/actions/index";

import '../../src/assets/bootstrap4-glyphicons/css/bootstrap-glyphicons.min.css'

const animatedComponents = makeAnimated();

let mytable = null;

class DataTable extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isOpen: true,
      isFixed: false,
      portalPlacement: 'bottom',
    };
    
  }

    componentDidMount() {

        //console.log(this.props.data);

        this.$el = $(this.el)
        mytable = this.$el.DataTable({
            deferRender: true,
            search: {regex: true},
            language: TblLanguage,
            data: this.props.data.listTaxon,
            
            columnDefs : [
              {
                 'targets': [0,1],
                 createdCell : (td, cellData, rowData, row, col) => {
                    
                    let mylink = 'flib/flora/' + rowData.id + '/' + rowData.latin.replace(/\s/g, "-") ;
                    ReactDOM.render(<div className="tbllink" onClick ={() => this.goToLink(`${mylink}`,`${rowData.id}`)}>{cellData}</div>,td);
            
                  }
              },{
            
                'targets': [3],
                createdCell : (td, cellData, rowData, row, col) => {
                   
                   let mylink = '/flib/flora/gattungen/' + rowData.genusID + '/' + cellData.replace(/\s/g, "-") ;
                   ReactDOM.render(<div className="tbllink" onClick ={() => this.goToLink(`${mylink}`,0)}>{cellData}</div>,td);
           
                 }
              },{
            
                'targets': [2],
                createdCell : (td, cellData, rowData, row, col) => {
                   
                   let mylink = '/flib/flora/familie/' + rowData.familyID + '/' + cellData.replace(/\s/g, "-") ;
                   ReactDOM.render(<div className="tbllink" onClick ={() => this.goToLink(`${mylink}`,0)}>{cellData}</div>,td);
           
                 }
              }
              
              ],
            columns: [
              {
                title: 'Wissenschaftlicher Name',
                data: 'latin',
                width: 350
              },
              {
                title: 'Deutscher Name',
                data: 'german',
                width: 250
              },
              {
                title:'Familie',
                data: 'familyName',
                width: 100
              },
              {
                title: 'Gattungen',
                data: 'genusName',
                width: 50
              },
              {
                title: 'TotalObs',
                data: 'nbrtaxaobs',
                width: 50
              },
              {
                title:  'Id',
                data: 'id',
                visible: false,
                width: 200
              },
              {
                title:  'famId',
                data: 'familyID',
                visible: false,
                width: 50
              },
              {
                title:  'genId',
                data: 'genusID',
                visible: false,
                width: 50
              }
            ]
        })

        /* mytable.columns([1,2]).every( function () {
            var column = this;
            var select = $('<select><option value=""></option></select>')
                .appendTo( $(column.footer()).empty() )
                .on( 'change', function () {
                  mytable.draw();
                } );

            column.data().unique().sort().each( function ( d, j ) {
                select.append( '<option value="'+d+'">'+d+'</option>' )
            } );
        } ); */
        
  }


  goToLink(myLink, taxonID){
    
    if(taxonID !== 0){
      this.props.getTaxon(taxonID)
    }
    
    this.props.history.push(myLink,{ data: this.props.data });
    
  }

    render() {


      function handleChangeFamily(e) {

        let val = []
        if (e !== null) {
          e.map(fil => {
            return val.push('^'+fil.value+'$')
          })
        }
       
        let regExSearch = val.length > 0 ?  val.join('|')  : '';
        mytable.column(6).search( regExSearch, true, false ).draw();
      }

      function handleChangeGenus(e) {

        let val = []
        if (e !== null) {
          e.map(fil => {return val.push('^'+fil.value+'$')})
        }
        
        let regExSearch = val.length > 0 ?  val.join('|')  : '';
        mytable.column(7).search( regExSearch , true, false ).draw();
      }

      function handleChangeLatin(e) {

        let val = []
        if (e !== null) {
          e.map(fil => {
            return val.push('^'+fil.value+'$')
          })
        }

        let regExSearch = val.length > 0 ?  val.join('|')  : '';
        mytable.column(5).search(  regExSearch , true, false ).draw();
      }

      function handleChangeGerman(e) {

        let val = []
        if (e !== null) {
          e.map(fil => {
            return val.push('^'+fil.value+'$')
          })
        }
        
        let regExSearch = val.length > 0 ?  val.join('|')  : '';
        mytable.column(5).search(  regExSearch , true, false ).draw();
      }
      
      let optionsFamily = [] 
      let optionsGenre = [] 
      let optionsLatin = [] 
      let optionsGerman = [] 

      this.props.data.ListFamily.map( o => {

        const {id: value, familyName: label , ...rest} = o; 
        const newObj = {value, label, ...rest}; 
        return optionsFamily.push(newObj);      
        
      }) 

      this.props.data.ListGenus.map( o => {

        const {id: value, genusName: label , ...rest} = o; 
        const newObj = {value, label, ...rest}; 
        return optionsGenre.push(newObj);      
        
      }) 

      this.props.data.listTaxon.map( o => {

        const {id: value, latin: label, ...rest} = o; 
        const newObj = {value, label, ...rest}; 
        return optionsLatin.push(newObj);          
        
      }) 

      this.props.data.listTaxon.map( o => {
        if(o.german !=="") {
          const {id: value, german: label, ...rest} = o; 
          const newObj = {value, label, ...rest}; 
          return optionsGerman.push(newObj); 
        } else{
          return optionsGerman
        } 
      })
     
        return (
          <div>
            <div className="accordion" id="filterPanel">
              <div className="card">
                <div className="card-header" id="headingOne">
                  <h2 className="mb-0 float-md-right">
                    <button className="btn btn-outline-success btn-sm" type="button" data-toggle="collapse" data-target="#filterAdvenced" aria-expanded="true" aria-controls="collapseOne">
                     Filter <i className="fas fa-filter"></i>
                    </button>
                  </h2>
                </div>
            
                <div id="filterAdvenced" className="collapse" aria-labelledby="headingOne" data-parent="#filterPanel">
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="selectFamilie">Familie</label>
                          <Select 
                          id='selectFamilie'
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          menuPosition={this.state.isFixed ? 'fixed' : 'absolute'}
                          menuPlacement={this.state.portalPlacement}
                          onChange = {handleChangeFamily}
                          closeMenuOnSelect={false}
                          placeholder="Wählen Sie die Familie Art"
                          noOptionsMessage={() => "Keine Art Familie vorhanden"}
                          components={animatedComponents}
                          isMulti
                          isSearchable
                          options={optionsFamily} />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="selectGenre">Gattungen</label>
                        <Select 
                          id='selectGenre'
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          menuPosition={this.state.isFixed ? 'fixed' : 'absolute'}
                          menuPlacement={this.state.portalPlacement}
                          onChange = {handleChangeGenus}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          isSearchable
                          placeholder="Wählen Sie die Gattungen Art"
                          noOptionsMessage={() => "Keine Gattungen vorhanden"}
                          options={optionsGenre} />
                      </div>
                    </div>
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="selectLatin">Wissenschaftlicher Name</label>
                          <Select 
                          filterOption={null}
                          id='selectLatin'
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          menuPosition={this.state.isFixed ? 'fixed' : 'absolute'}
                          menuPlacement={this.state.portalPlacement}
                          onChange = {handleChangeLatin}
                          closeMenuOnSelect={false}
                          placeholder="Wählen Sie die Wissenschaftlicher Name Art"
                          noOptionsMessage={() => "Keine Wissenschaftlicher Name vorhanden"}
                          components={animatedComponents}
                          isMulti
                          isSearchable
                          options={optionsLatin} />
                      </div>
                      <div className="form-group col-md-6">
                        <label htmlFor="selectGerman">Deutscher Name</label>
                        <Select 
                          id='selectGerman'
                          styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                          menuPortalTarget={document.body}
                          menuPosition={this.state.isFixed ? 'fixed' : 'absolute'}
                          menuPlacement={this.state.portalPlacement}
                          onChange = {handleChangeGerman}
                          closeMenuOnSelect={false}
                          components={animatedComponents}
                          isMulti
                          isSearchable
                          placeholder="Wählen Sie die Deutscher Name Art"
                          noOptionsMessage={() => "Keine Deutscher Name vorhanden"}
                          options={optionsGerman} />
                      </div>
                    </div>
                    </div>
                </div>
              </div>
            </div>
            <div className="mt-3">
              
                <table className="table display compact table-striped table-bordered" width="100%" ref = {el => this.el = el }/> 
            </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
  //console.log("mapStateToProps SearchAuto",state);
  return {
    taxonRemoteData: state.remoteTaxon[0]
  };
}


export default withRouter(connect(mapStateToProps, { getTaxon } )((DataTable)));
