export const communBernLimit = {
	"type": "FeatureCollection",
	"features": [
		{
			"type": "Feature",
			"geometry": {
				"type": "MultiPolygon",
				"coordinates":[
                    [
                        [
                            [
                                7.331228,
                                46.919235
                            ],
                            [
                                7.327458,
                                46.919033
                            ],
                            [
                                7.326918,
                                46.923595
                            ],
                            [
                                7.326775,
                                46.923761
                            ],
                            [
                                7.326474,
                                46.924196
                            ],
                            [
                                7.326404,
                                46.924184
                            ],
                            [
                                7.326197,
                                46.92431
                            ],
                            [
                                7.325795,
                                46.924644
                            ],
                            [
                                7.325259,
                                46.924689
                            ],
                            [
                                7.324752,
                                46.924945
                            ],
                            [
                                7.32478,
                                46.925116
                            ],
                            [
                                7.324643,
                                46.925135
                            ],
                            [
                                7.324506,
                                46.92515
                            ],
                            [
                                7.324318,
                                46.925164
                            ],
                            [
                                7.324167,
                                46.925169
                            ],
                            [
                                7.323164,
                                46.92518
                            ],
                            [
                                7.322881,
                                46.925161
                            ],
                            [
                                7.322193,
                                46.925088
                            ],
                            [
                                7.321672,
                                46.925061
                            ],
                            [
                                7.321242,
                                46.925077
                            ],
                            [
                                7.32123,
                                46.925058
                            ],
                            [
                                7.320995,
                                46.925108
                            ],
                            [
                                7.320885,
                                46.925141
                            ],
                            [
                                7.320771,
                                46.925167
                            ],
                            [
                                7.320655,
                                46.925185
                            ],
                            [
                                7.320536,
                                46.925196
                            ],
                            [
                                7.320416,
                                46.925199
                            ],
                            [
                                7.320297,
                                46.925195
                            ],
                            [
                                7.320184,
                                46.925187
                            ],
                            [
                                7.320072,
                                46.925175
                            ],
                            [
                                7.319961,
                                46.92516
                            ],
                            [
                                7.319851,
                                46.925142
                            ],
                            [
                                7.319742,
                                46.92512
                            ],
                            [
                                7.319635,
                                46.925095
                            ],
                            [
                                7.319067,
                                46.924894
                            ],
                            [
                                7.318746,
                                46.92481
                            ],
                            [
                                7.317678,
                                46.924588
                            ],
                            [
                                7.317507,
                                46.924546
                            ],
                            [
                                7.317398,
                                46.924516
                            ],
                            [
                                7.317292,
                                46.924483
                            ],
                            [
                                7.317137,
                                46.92443
                            ],
                            [
                                7.315647,
                                46.923854
                            ],
                            [
                                7.314829,
                                46.9235
                            ],
                            [
                                7.314713,
                                46.923453
                            ],
                            [
                                7.314595,
                                46.923409
                            ],
                            [
                                7.314469,
                                46.923366
                            ],
                            [
                                7.314336,
                                46.923325
                            ],
                            [
                                7.314201,
                                46.923288
                            ],
                            [
                                7.314133,
                                46.92327
                            ],
                            [
                                7.312666,
                                46.922938
                            ],
                            [
                                7.311763,
                                46.922717
                            ],
                            [
                                7.310247,
                                46.922434
                            ],
                            [
                                7.310075,
                                46.922409
                            ],
                            [
                                7.309904,
                                46.922387
                            ],
                            [
                                7.309732,
                                46.922366
                            ],
                            [
                                7.309559,
                                46.922348
                            ],
                            [
                                7.309386,
                                46.922331
                            ],
                            [
                                7.309212,
                                46.922317
                            ],
                            [
                                7.309049,
                                46.922306
                            ],
                            [
                                7.308885,
                                46.922297
                            ],
                            [
                                7.308721,
                                46.922289
                            ],
                            [
                                7.308557,
                                46.922283
                            ],
                            [
                                7.308393,
                                46.922279
                            ],
                            [
                                7.308228,
                                46.922278
                            ],
                            [
                                7.30742,
                                46.922274
                            ],
                            [
                                7.306986,
                                46.922249
                            ],
                            [
                                7.306852,
                                46.922247
                            ],
                            [
                                7.306784,
                                46.922248
                            ],
                            [
                                7.306716,
                                46.92225
                            ],
                            [
                                7.306648,
                                46.922253
                            ],
                            [
                                7.306581,
                                46.922258
                            ],
                            [
                                7.305717,
                                46.922328
                            ],
                            [
                                7.305062,
                                46.922325
                            ],
                            [
                                7.303887,
                                46.922366
                            ],
                            [
                                7.30324,
                                46.922418
                            ],
                            [
                                7.302492,
                                46.922538
                            ],
                            [
                                7.300894,
                                46.922666
                            ],
                            [
                                7.300138,
                                46.92275
                            ],
                            [
                                7.29854,
                                46.922871
                            ],
                            [
                                7.297969,
                                46.922881
                            ],
                            [
                                7.297773,
                                46.92286
                            ],
                            [
                                7.29733,
                                46.922858
                            ],
                            [
                                7.296755,
                                46.922897
                            ],
                            [
                                7.295392,
                                46.922888
                            ],
                            [
                                7.294974,
                                46.92287
                            ],
                            [
                                7.294825,
                                46.923011
                            ],
                            [
                                7.294389,
                                46.923488999999999
                            ],
                            [
                                7.294317,
                                46.923649
                            ],
                            [
                                7.294314,
                                46.923743
                            ],
                            [
                                7.294449,
                                46.923982
                            ],
                            [
                                7.29487,
                                46.924498
                            ],
                            [
                                7.294936,
                                46.924557
                            ],
                            [
                                7.295052,
                                46.924698
                            ],
                            [
                                7.295399,
                                46.925046
                            ],
                            [
                                7.295753,
                                46.925282
                            ],
                            [
                                7.296174,
                                46.92574
                            ],
                            [
                                7.296361,
                                46.925888
                            ],
                            [
                                7.296627,
                                46.92607
                            ],
                            [
                                7.296902,
                                46.926215
                            ],
                            [
                                7.296938,
                                46.926272
                            ],
                            [
                                7.297073,
                                46.926399
                            ],
                            [
                                7.297231,
                                46.926538
                            ],
                            [
                                7.297808,
                                46.927023
                            ],
                            [
                                7.297925,
                                46.927157
                            ],
                            [
                                7.298267,
                                46.927464
                            ],
                            [
                                7.298478,
                                46.927594
                            ],
                            [
                                7.298751,
                                46.927685
                            ],
                            [
                                7.298872,
                                46.927791
                            ],
                            [
                                7.299108,
                                46.927917
                            ],
                            [
                                7.299584,
                                46.92804
                            ],
                            [
                                7.299765,
                                46.928113
                            ],
                            [
                                7.300112,
                                46.928149
                            ],
                            [
                                7.300264,
                                46.928175
                            ],
                            [
                                7.300725,
                                46.928237
                            ],
                            [
                                7.300865,
                                46.928236
                            ],
                            [
                                7.301305,
                                46.928358
                            ],
                            [
                                7.301344,
                                46.928413
                            ],
                            [
                                7.301441,
                                46.928462
                            ],
                            [
                                7.301434,
                                46.928476
                            ],
                            [
                                7.301724,
                                46.928493
                            ],
                            [
                                7.301871,
                                46.928565
                            ],
                            [
                                7.302173,
                                46.928812
                            ],
                            [
                                7.302271,
                                46.928919
                            ],
                            [
                                7.302466,
                                46.929153
                            ],
                            [
                                7.30261,
                                46.929369
                            ],
                            [
                                7.302778,
                                46.929513
                            ],
                            [
                                7.302906,
                                46.929566
                            ],
                            [
                                7.303208,
                                46.929668
                            ],
                            [
                                7.303513,
                                46.929816
                            ],
                            [
                                7.303693,
                                46.929888
                            ],
                            [
                                7.303904,
                                46.93005
                            ],
                            [
                                7.304083,
                                46.930238
                            ],
                            [
                                7.304274,
                                46.930339
                            ],
                            [
                                7.304455,
                                46.930415
                            ],
                            [
                                7.304643,
                                46.930433
                            ],
                            [
                                7.305081,
                                46.930519
                            ],
                            [
                                7.305249,
                                46.93055
                            ],
                            [
                                7.305561,
                                46.930701
                            ],
                            [
                                7.305666,
                                46.930801
                            ],
                            [
                                7.305987,
                                46.930818
                            ],
                            [
                                7.306154,
                                46.930944
                            ],
                            [
                                7.306263,
                                46.931134
                            ],
                            [
                                7.306357,
                                46.931233
                            ],
                            [
                                7.306403,
                                46.931377
                            ],
                            [
                                7.30626,
                                46.931716
                            ],
                            [
                                7.3061,
                                46.932091
                            ],
                            [
                                7.305933,
                                46.932267
                            ],
                            [
                                7.305679,
                                46.932537
                            ],
                            [
                                7.305453,
                                46.93278
                            ],
                            [
                                7.305278,
                                46.93297
                            ],
                            [
                                7.305247,
                                46.933018
                            ],
                            [
                                7.30564,
                                46.933117
                            ],
                            [
                                7.305981,
                                46.933219
                            ],
                            [
                                7.305781,
                                46.933657
                            ],
                            [
                                7.305733,
                                46.933834
                            ],
                            [
                                7.305634,
                                46.933992
                            ],
                            [
                                7.305605,
                                46.934039
                            ],
                            [
                                7.305556,
                                46.934151
                            ],
                            [
                                7.305456,
                                46.934307
                            ],
                            [
                                7.305343,
                                46.934395
                            ],
                            [
                                7.305238,
                                46.934489
                            ],
                            [
                                7.305171,
                                46.934676
                            ],
                            [
                                7.305111,
                                46.934927
                            ],
                            [
                                7.304956,
                                46.93504
                            ],
                            [
                                7.304523,
                                46.93529
                            ],
                            [
                                7.304459,
                                46.935431
                            ],
                            [
                                7.304482,
                                46.935836
                            ],
                            [
                                7.304337,
                                46.936233
                            ],
                            [
                                7.304522,
                                46.936481
                            ],
                            [
                                7.304647,
                                46.936681
                            ],
                            [
                                7.3047,
                                46.936868
                            ],
                            [
                                7.304722,
                                46.937175
                            ],
                            [
                                7.304705,
                                46.937275
                            ],
                            [
                                7.304702,
                                46.93739
                            ],
                            [
                                7.304716,
                                46.937662
                            ],
                            [
                                7.304667,
                                46.93793
                            ],
                            [
                                7.304604,
                                46.938132
                            ],
                            [
                                7.304602,
                                46.938253
                            ],
                            [
                                7.304654,
                                46.938339
                            ],
                            [
                                7.305164,
                                46.938752
                            ],
                            [
                                7.305329,
                                46.938835
                            ],
                            [
                                7.305434,
                                46.938868
                            ],
                            [
                                7.305669,
                                46.938989
                            ],
                            [
                                7.305845,
                                46.939056
                            ],
                            [
                                7.306092,
                                46.939123
                            ],
                            [
                                7.30628,
                                46.939166
                            ],
                            [
                                7.306343,
                                46.939197
                            ],
                            [
                                7.306407,
                                46.939282
                            ],
                            [
                                7.30637,
                                46.939359
                            ],
                            [
                                7.306253,
                                46.939437
                            ],
                            [
                                7.306534,
                                46.939636
                            ],
                            [
                                7.306455,
                                46.939801
                            ],
                            [
                                7.306454,
                                46.939851
                            ],
                            [
                                7.306438,
                                46.939961
                            ],
                            [
                                7.306605,
                                46.940005
                            ],
                            [
                                7.306804,
                                46.940033
                            ],
                            [
                                7.306858,
                                46.940038
                            ],
                            [
                                7.306858,
                                46.940131
                            ],
                            [
                                7.306861,
                                46.940372
                            ],
                            [
                                7.309813,
                                46.941499
                            ],
                            [
                                7.309932,
                                46.941518
                            ],
                            [
                                7.30999,
                                46.941499
                            ],
                            [
                                7.310449,
                                46.941487
                            ],
                            [
                                7.310669,
                                46.941507
                            ],
                            [
                                7.310856,
                                46.941559
                            ],
                            [
                                7.31109,
                                46.941581
                            ],
                            [
                                7.311206,
                                46.941612
                            ],
                            [
                                7.311763,
                                46.941678
                            ],
                            [
                                7.312124,
                                46.941693
                            ],
                            [
                                7.312426,
                                46.941732
                            ],
                            [
                                7.312536,
                                46.941725
                            ],
                            [
                                7.312846,
                                46.941755
                            ],
                            [
                                7.313079,
                                46.941761
                            ],
                            [
                                7.313179,
                                46.941785
                            ],
                            [
                                7.313649,
                                46.941822
                            ],
                            [
                                7.313676,
                                46.941808
                            ],
                            [
                                7.313731,
                                46.941818
                            ],
                            [
                                7.313774,
                                46.941863
                            ],
                            [
                                7.313882,
                                46.941929
                            ],
                            [
                                7.313926,
                                46.94194
                            ],
                            [
                                7.314018,
                                46.942
                            ],
                            [
                                7.314068,
                                46.942044
                            ],
                            [
                                7.314663,
                                46.942351
                            ],
                            [
                                7.314752,
                                46.942416
                            ],
                            [
                                7.314929,
                                46.942482
                            ],
                            [
                                7.315093,
                                46.942457
                            ],
                            [
                                7.315202,
                                46.942496
                            ],
                            [
                                7.315351,
                                46.942465
                            ],
                            [
                                7.31558,
                                46.942373
                            ],
                            [
                                7.315708,
                                46.942357
                            ],
                            [
                                7.315789,
                                46.942365
                            ],
                            [
                                7.315905,
                                46.942338
                            ],
                            [
                                7.316166,
                                46.942262
                            ],
                            [
                                7.316205,
                                46.942286
                            ],
                            [
                                7.316481,
                                46.942252
                            ],
                            [
                                7.316628,
                                46.94228
                            ],
                            [
                                7.316839,
                                46.942249
                            ],
                            [
                                7.316992,
                                46.942257
                            ],
                            [
                                7.317059,
                                46.942282
                            ],
                            [
                                7.317105,
                                46.94229
                            ],
                            [
                                7.31741,
                                46.942367
                            ],
                            [
                                7.317487,
                                46.942408
                            ],
                            [
                                7.317709,
                                46.942443
                            ],
                            [
                                7.317792,
                                46.942423
                            ],
                            [
                                7.317955,
                                46.942454
                            ],
                            [
                                7.31807,
                                46.942435
                            ],
                            [
                                7.318197,
                                46.942507
                            ],
                            [
                                7.318341,
                                46.942478
                            ],
                            [
                                7.318487,
                                46.942524
                            ],
                            [
                                7.318761,
                                46.942538
                            ],
                            [
                                7.31882,
                                46.942498
                            ],
                            [
                                7.318883,
                                46.942548
                            ],
                            [
                                7.318971,
                                46.942569
                            ],
                            [
                                7.318996,
                                46.942529
                            ],
                            [
                                7.319075,
                                46.942522
                            ],
                            [
                                7.319437,
                                46.942659
                            ],
                            [
                                7.319489,
                                46.942631
                            ],
                            [
                                7.319645,
                                46.942662
                            ],
                            [
                                7.319707,
                                46.942697
                            ],
                            [
                                7.319914,
                                46.94274
                            ],
                            [
                                7.320748,
                                46.942814
                            ],
                            [
                                7.320883,
                                46.942862
                            ],
                            [
                                7.321251,
                                46.942923
                            ],
                            [
                                7.321541,
                                46.942949
                            ],
                            [
                                7.321669,
                                46.942985
                            ],
                            [
                                7.321703,
                                46.943009
                            ],
                            [
                                7.321965,
                                46.943035
                            ],
                            [
                                7.322015,
                                46.943032
                            ],
                            [
                                7.322099,
                                46.943066
                            ],
                            [
                                7.322268,
                                46.943073
                            ],
                            [
                                7.322438,
                                46.943151
                            ],
                            [
                                7.3226,
                                46.943122
                            ],
                            [
                                7.322872,
                                46.943142
                            ],
                            [
                                7.322998,
                                46.943124
                            ],
                            [
                                7.323167,
                                46.943133
                            ],
                            [
                                7.323279,
                                46.943155
                            ],
                            [
                                7.323578,
                                46.943153
                            ],
                            [
                                7.323631,
                                46.943167
                            ],
                            [
                                7.32365,
                                46.943154
                            ],
                            [
                                7.32393,
                                46.943199
                            ],
                            [
                                7.324041,
                                46.9432
                            ],
                            [
                                7.324393,
                                46.94325
                            ],
                            [
                                7.324563,
                                46.943259
                            ],
                            [
                                7.324673,
                                46.943286
                            ],
                            [
                                7.324739,
                                46.943285
                            ],
                            [
                                7.324787,
                                46.943327
                            ],
                            [
                                7.324766,
                                46.943417
                            ],
                            [
                                7.324872,
                                46.943451
                            ],
                            [
                                7.324922,
                                46.943532
                            ],
                            [
                                7.324899,
                                46.943581
                            ],
                            [
                                7.32499,
                                46.943612
                            ],
                            [
                                7.325027,
                                46.943704
                            ],
                            [
                                7.325133,
                                46.943763
                            ],
                            [
                                7.325245,
                                46.943881
                            ],
                            [
                                7.325372,
                                46.943931
                            ],
                            [
                                7.325615,
                                46.944233
                            ],
                            [
                                7.325831,
                                46.944354
                            ],
                            [
                                7.326131,
                                46.94461
                            ],
                            [
                                7.326241,
                                46.944922
                            ],
                            [
                                7.326174,
                                46.945113
                            ],
                            [
                                7.326199,
                                46.945186
                            ],
                            [
                                7.326314,
                                46.94525
                            ],
                            [
                                7.326552,
                                46.94534
                            ],
                            [
                                7.326665,
                                46.945438
                            ],
                            [
                                7.326733,
                                46.945508
                            ],
                            [
                                7.327526,
                                46.946003
                            ],
                            [
                                7.3276,
                                46.946068
                            ],
                            [
                                7.328235,
                                46.946362
                            ],
                            [
                                7.328357,
                                46.946392
                            ],
                            [
                                7.328454,
                                46.94641
                            ],
                            [
                                7.328742,
                                46.946441
                            ],
                            [
                                7.328852,
                                46.946456
                            ],
                            [
                                7.329126,
                                46.946476
                            ],
                            [
                                7.329799,
                                46.946507
                            ],
                            [
                                7.33003,
                                46.946548
                            ],
                            [
                                7.330119,
                                46.946544
                            ],
                            [
                                7.330359,
                                46.946541
                            ],
                            [
                                7.330593,
                                46.946568
                            ],
                            [
                                7.331034,
                                46.94663
                            ],
                            [
                                7.331247,
                                46.946508
                            ],
                            [
                                7.331345,
                                46.946436
                            ],
                            [
                                7.331359,
                                46.946421
                            ],
                            [
                                7.331413,
                                46.946349
                            ],
                            [
                                7.331459,
                                46.946264
                            ],
                            [
                                7.331466,
                                46.946236
                            ],
                            [
                                7.331465,
                                46.946232
                            ],
                            [
                                7.331527,
                                46.946212
                            ],
                            [
                                7.331899,
                                46.946112
                            ],
                            [
                                7.332163,
                                46.946086
                            ],
                            [
                                7.332329,
                                46.946095
                            ],
                            [
                                7.332431,
                                46.946079
                            ],
                            [
                                7.33271,
                                46.946098
                            ],
                            [
                                7.332891,
                                46.946095
                            ],
                            [
                                7.333112,
                                46.946112
                            ],
                            [
                                7.333388,
                                46.946102
                            ],
                            [
                                7.333315,
                                46.94621
                            ],
                            [
                                7.333308,
                                46.946277
                            ],
                            [
                                7.333538,
                                46.946256
                            ],
                            [
                                7.333731,
                                46.946249
                            ],
                            [
                                7.333909,
                                46.946281
                            ],
                            [
                                7.334072,
                                46.946166
                            ],
                            [
                                7.334155,
                                46.946197
                            ],
                            [
                                7.334266,
                                46.946209
                            ],
                            [
                                7.334435,
                                46.946168
                            ],
                            [
                                7.334572,
                                46.946166
                            ],
                            [
                                7.33502,
                                46.946105
                            ],
                            [
                                7.335173,
                                46.946076
                            ],
                            [
                                7.335226,
                                46.946078
                            ],
                            [
                                7.33536,
                                46.946026
                            ],
                            [
                                7.335495,
                                46.946002
                            ],
                            [
                                7.335731,
                                46.946
                            ],
                            [
                                7.335802,
                                46.946027
                            ],
                            [
                                7.336171,
                                46.946047
                            ],
                            [
                                7.336259,
                                46.94606
                            ],
                            [
                                7.336319,
                                46.946076
                            ],
                            [
                                7.336494,
                                46.946099
                            ],
                            [
                                7.336688,
                                46.946209
                            ],
                            [
                                7.336894,
                                46.946255
                            ],
                            [
                                7.337046,
                                46.946315
                            ],
                            [
                                7.337202,
                                46.946294
                            ],
                            [
                                7.337494,
                                46.946305
                            ],
                            [
                                7.337546,
                                46.946324
                            ],
                            [
                                7.33775,
                                46.946321
                            ],
                            [
                                7.337895,
                                46.946311
                            ],
                            [
                                7.337939,
                                46.94626
                            ],
                            [
                                7.338043,
                                46.946201
                            ],
                            [
                                7.338172,
                                46.946191
                            ],
                            [
                                7.33837,
                                46.946094
                            ],
                            [
                                7.338517,
                                46.946119
                            ],
                            [
                                7.33878,
                                46.946088
                            ],
                            [
                                7.338844,
                                46.946106
                            ],
                            [
                                7.339024,
                                46.946102
                            ],
                            [
                                7.339112,
                                46.946078
                            ],
                            [
                                7.339386,
                                46.946074
                            ],
                            [
                                7.339453,
                                46.946058
                            ],
                            [
                                7.339625,
                                46.946074
                            ],
                            [
                                7.339767,
                                46.946056
                            ],
                            [
                                7.339939,
                                46.946068
                            ],
                            [
                                7.340158,
                                46.946147
                            ],
                            [
                                7.340347,
                                46.946177
                            ],
                            [
                                7.340679,
                                46.946237000000007
                            ],
                            [
                                7.340874,
                                46.946189
                            ],
                            [
                                7.340995,
                                46.946126
                            ],
                            [
                                7.341166,
                                46.946075
                            ],
                            [
                                7.341406,
                                46.946081
                            ],
                            [
                                7.341412,
                                46.946361
                            ],
                            [
                                7.341497,
                                46.947194
                            ],
                            [
                                7.342147,
                                46.947092
                            ],
                            [
                                7.342238,
                                46.94724
                            ],
                            [
                                7.343225,
                                46.947277
                            ],
                            [
                                7.343847,
                                46.947372
                            ],
                            [
                                7.343803,
                                46.947521
                            ],
                            [
                                7.343564,
                                46.948394
                            ],
                            [
                                7.343456,
                                46.948795
                            ],
                            [
                                7.346593,
                                46.949424
                            ],
                            [
                                7.346644,
                                46.949432
                            ],
                            [
                                7.346679,
                                46.949181
                            ],
                            [
                                7.3467,
                                46.949003
                            ],
                            [
                                7.346824,
                                46.948121
                            ],
                            [
                                7.347347,
                                46.948288
                            ],
                            [
                                7.347403,
                                46.948207
                            ],
                            [
                                7.347375,
                                46.948145
                            ],
                            [
                                7.347388,
                                46.948113
                            ],
                            [
                                7.3474,
                                46.948053
                            ],
                            [
                                7.347511,
                                46.947909
                            ],
                            [
                                7.347534,
                                46.94779
                            ],
                            [
                                7.34757,
                                46.947692
                            ],
                            [
                                7.347655,
                                46.947659
                            ],
                            [
                                7.348509,
                                46.947826
                            ],
                            [
                                7.348635,
                                46.947841
                            ],
                            [
                                7.349083,
                                46.947831
                            ],
                            [
                                7.349389,
                                46.947915
                            ],
                            [
                                7.349549,
                                46.948048
                            ],
                            [
                                7.34987,
                                46.948178
                            ],
                            [
                                7.350117,
                                46.948259
                            ],
                            [
                                7.350354,
                                46.948283
                            ],
                            [
                                7.3507,
                                46.948374
                            ],
                            [
                                7.351009,
                                46.948465
                            ],
                            [
                                7.351358,
                                46.948549
                            ],
                            [
                                7.351331,
                                46.948596
                            ],
                            [
                                7.350841,
                                46.949642
                            ],
                            [
                                7.350564,
                                46.950214
                            ],
                            [
                                7.351265,
                                46.950339
                            ],
                            [
                                7.352281,
                                46.950492
                            ],
                            [
                                7.352407,
                                46.950527
                            ],
                            [
                                7.352514,
                                46.950532
                            ],
                            [
                                7.352613,
                                46.950774
                            ],
                            [
                                7.35289,
                                46.951424
                            ],
                            [
                                7.35308,
                                46.951889
                            ],
                            [
                                7.353091,
                                46.951927
                            ],
                            [
                                7.355137,
                                46.951705
                            ],
                            [
                                7.355549,
                                46.951652
                            ],
                            [
                                7.356852,
                                46.95152
                            ],
                            [
                                7.357245,
                                46.951458
                            ],
                            [
                                7.357621,
                                46.951363
                            ],
                            [
                                7.357984,
                                46.951249
                            ],
                            [
                                7.358204,
                                46.951178
                            ],
                            [
                                7.358215,
                                46.95141
                            ],
                            [
                                7.358184,
                                46.951711
                            ],
                            [
                                7.358833,
                                46.951869
                            ],
                            [
                                7.359216,
                                46.952019
                            ],
                            [
                                7.359839,
                                46.952275
                            ],
                            [
                                7.360375,
                                46.952579
                            ],
                            [
                                7.3609,
                                46.952837
                            ],
                            [
                                7.361245,
                                46.953037
                            ],
                            [
                                7.361748,
                                46.95328
                            ],
                            [
                                7.362174,
                                46.95346
                            ],
                            [
                                7.362822,
                                46.953752
                            ],
                            [
                                7.363428,
                                46.954049
                            ],
                            [
                                7.363547,
                                46.954101
                            ],
                            [
                                7.363945,
                                46.954306
                            ],
                            [
                                7.36457,
                                46.954634
                            ],
                            [
                                7.365044,
                                46.95498
                            ],
                            [
                                7.365393,
                                46.955263
                            ],
                            [
                                7.365594,
                                46.955336
                            ],
                            [
                                7.365946,
                                46.955432
                            ],
                            [
                                7.366298,
                                46.955592
                            ],
                            [
                                7.366573,
                                46.955769
                            ],
                            [
                                7.367103,
                                46.956126
                            ],
                            [
                                7.367728,
                                46.956334
                            ],
                            [
                                7.36803,
                                46.956383
                            ],
                            [
                                7.368508,
                                46.956632
                            ],
                            [
                                7.369063,
                                46.957133
                            ],
                            [
                                7.369395,
                                46.957354
                            ],
                            [
                                7.369532,
                                46.957532
                            ],
                            [
                                7.369362,
                                46.957624
                            ],
                            [
                                7.370491,
                                46.958962
                            ],
                            [
                                7.370209,
                                46.959086
                            ],
                            [
                                7.369844,
                                46.959251
                            ],
                            [
                                7.371511,
                                46.960735
                            ],
                            [
                                7.375031,
                                46.959836
                            ],
                            [
                                7.378513,
                                46.961469
                            ],
                            [
                                7.380625,
                                46.965475
                            ],
                            [
                                7.380694,
                                46.965519
                            ],
                            [
                                7.383211,
                                46.966302
                            ],
                            [
                                7.393801,
                                46.966928
                            ],
                            [
                                7.400054,
                                46.969179
                            ],
                            [
                                7.408294,
                                46.968687
                            ],
                            [
                                7.412144,
                                46.969875
                            ],
                            [
                                7.415941,
                                46.971045
                            ],
                            [
                                7.418359,
                                46.971292
                            ],
                            [
                                7.418475,
                                46.971311
                            ],
                            [
                                7.421341,
                                46.971559
                            ],
                            [
                                7.426216,
                                46.973295
                            ],
                            [
                                7.428173,
                                46.973805
                            ],
                            [
                                7.429658,
                                46.973751
                            ],
                            [
                                7.43113,
                                46.973418
                            ],
                            [
                                7.432707,
                                46.97269
                            ],
                            [
                                7.433988,
                                46.971341
                            ],
                            [
                                7.434738,
                                46.969074
                            ],
                            [
                                7.435362,
                                46.968435
                            ],
                            [
                                7.436492,
                                46.968183
                            ],
                            [
                                7.437854,
                                46.968431
                            ],
                            [
                                7.438594,
                                46.968921
                            ],
                            [
                                7.439343,
                                46.970027
                            ],
                            [
                                7.439593,
                                46.974471
                            ],
                            [
                                7.439895,
                                46.975371
                            ],
                            [
                                7.440397,
                                46.976034
                            ],
                            [
                                7.441249,
                                46.976787
                            ],
                            [
                                7.442261,
                                46.976981
                            ],
                            [
                                7.443286,
                                46.976819
                            ],
                            [
                                7.444817,
                                46.975757
                            ],
                            [
                                7.445691,
                                46.974795
                            ],
                            [
                                7.447018,
                                46.974898
                            ],
                            [
                                7.448385,
                                46.97564
                            ],
                            [
                                7.449049,
                                46.977007
                            ],
                            [
                                7.448674,
                                46.978321
                            ],
                            [
                                7.447702,
                                46.979013
                            ],
                            [
                                7.446519,
                                46.979013
                            ],
                            [
                                7.444048,
                                46.978069
                            ],
                            [
                                7.442471,
                                46.978492
                            ],
                            [
                                7.441564,
                                46.979041
                            ],
                            [
                                7.441275,
                                46.979823
                            ],
                            [
                                7.441729,
                                46.980502
                            ],
                            [
                                7.443028,
                                46.981333
                            ],
                            [
                                7.444929,
                                46.982513
                            ],
                            [
                                7.446625,
                                46.984366
                            ],
                            [
                                7.447138,
                                46.985445
                            ],
                            [
                                7.447269,
                                46.986983
                            ],
                            [
                                7.447638,
                                46.988107
                            ],
                            [
                                7.448492,
                                46.989263
                            ],
                            [
                                7.450675,
                                46.990154
                            ],
                            [
                                7.451463,
                                46.990122
                            ],
                            [
                                7.452212,
                                46.989681
                            ],
                            [
                                7.45239,
                                46.989051
                            ],
                            [
                                7.452782,
                                46.983574
                            ],
                            [
                                7.452939,
                                46.983164
                            ],
                            [
                                7.453361,
                                46.982053
                            ],
                            [
                                7.453534,
                                46.981486
                            ],
                            [
                                7.454462,
                                46.978221
                            ],
                            [
                                7.45465,
                                46.977819
                            ],
                            [
                                7.455005,
                                46.977474
                            ],
                            [
                                7.455482,
                                46.977213
                            ],
                            [
                                7.45601,
                                46.977076
                            ],
                            [
                                7.456914,
                                46.976957
                            ],
                            [
                                7.463603,
                                46.976209
                            ],
                            [
                                7.465483,
                                46.976222
                            ],
                            [
                                7.466179,
                                46.976105
                            ],
                            [
                                7.466731,
                                46.975826
                            ],
                            [
                                7.467019,
                                46.975472
                            ],
                            [
                                7.467356,
                                46.975583
                            ],
                            [
                                7.468039,
                                46.975827
                            ],
                            [
                                7.468179,
                                46.975614
                            ],
                            [
                                7.468314,
                                46.975308
                            ],
                            [
                                7.468367,
                                46.975065
                            ],
                            [
                                7.468446,
                                46.974327
                            ],
                            [
                                7.468464,
                                46.974223
                            ],
                            [
                                7.468497,
                                46.974126
                            ],
                            [
                                7.468529,
                                46.974051
                            ],
                            [
                                7.468592,
                                46.973942
                            ],
                            [
                                7.468659,
                                46.973851
                            ],
                            [
                                7.468743,
                                46.97376
                            ],
                            [
                                7.468816,
                                46.973691
                            ],
                            [
                                7.469331,
                                46.9733
                            ],
                            [
                                7.469457,
                                46.973181
                            ],
                            [
                                7.469562,
                                46.973059
                            ],
                            [
                                7.469708,
                                46.972829
                            ],
                            [
                                7.469844,
                                46.972848
                            ],
                            [
                                7.469927,
                                46.97259
                            ],
                            [
                                7.469962,
                                46.972317
                            ],
                            [
                                7.470015,
                                46.971469
                            ],
                            [
                                7.470045,
                                46.971092
                            ],
                            [
                                7.470065,
                                46.971004
                            ],
                            [
                                7.470085,
                                46.970953
                            ],
                            [
                                7.470114,
                                46.970901
                            ],
                            [
                                7.470198,
                                46.970793
                            ],
                            [
                                7.470541,
                                46.970456
                            ],
                            [
                                7.470632,
                                46.970351
                            ],
                            [
                                7.470709,
                                46.970238
                            ],
                            [
                                7.470761,
                                46.970122
                            ],
                            [
                                7.470793,
                                46.969999
                            ],
                            [
                                7.4708,
                                46.969876
                            ],
                            [
                                7.470785,
                                46.969753
                            ],
                            [
                                7.470746,
                                46.969632
                            ],
                            [
                                7.470687,
                                46.969508
                            ],
                            [
                                7.470821,
                                46.969464
                            ],
                            [
                                7.470657,
                                46.969251
                            ],
                            [
                                7.470541,
                                46.969124
                            ],
                            [
                                7.470468,
                                46.969024
                            ],
                            [
                                7.47041,
                                46.968923
                            ],
                            [
                                7.470367,
                                46.968817
                            ],
                            [
                                7.470343,
                                46.968706
                            ],
                            [
                                7.470248,
                                46.968022
                            ],
                            [
                                7.470276,
                                46.96799
                            ],
                            [
                                7.470368,
                                46.967986
                            ],
                            [
                                7.470191,
                                46.967412
                            ],
                            [
                                7.471064,
                                46.968578
                            ],
                            [
                                7.471339,
                                46.968677
                            ],
                            [
                                7.472403,
                                46.968991
                            ],
                            [
                                7.473449,
                                46.969435
                            ],
                            [
                                7.473744,
                                46.969515
                            ],
                            [
                                7.473927,
                                46.969546
                            ],
                            [
                                7.474958,
                                46.969579
                            ],
                            [
                                7.475541,
                                46.969605
                            ],
                            [
                                7.47589,
                                46.969631
                            ],
                            [
                                7.476012,
                                46.969586
                            ],
                            [
                                7.476347,
                                46.969622
                            ],
                            [
                                7.476749,
                                46.969699
                            ],
                            [
                                7.476844,
                                46.96984
                            ],
                            [
                                7.476852,
                                46.970002
                            ],
                            [
                                7.476851,
                                46.970175
                            ],
                            [
                                7.477012,
                                46.970505
                            ],
                            [
                                7.477374,
                                46.970759
                            ],
                            [
                                7.477543,
                                46.970835
                            ],
                            [
                                7.477833,
                                46.970918
                            ],
                            [
                                7.477941,
                                46.97098
                            ],
                            [
                                7.478044,
                                46.9711
                            ],
                            [
                                7.478163,
                                46.971183
                            ],
                            [
                                7.47865,
                                46.971208
                            ],
                            [
                                7.479138,
                                46.971186
                            ],
                            [
                                7.479501,
                                46.971181
                            ],
                            [
                                7.47968,
                                46.9712
                            ],
                            [
                                7.479865,
                                46.971252
                            ],
                            [
                                7.480623,
                                46.97154
                            ],
                            [
                                7.482551,
                                46.971293
                            ],
                            [
                                7.483456,
                                46.970997
                            ],
                            [
                                7.483697,
                                46.970875
                            ],
                            [
                                7.483921,
                                46.970755
                            ],
                            [
                                7.484525,
                                46.970158
                            ],
                            [
                                7.484784,
                                46.969676
                            ],
                            [
                                7.485056,
                                46.96906
                            ],
                            [
                                7.485201,
                                46.967952
                            ],
                            [
                                7.485674,
                                46.967936
                            ],
                            [
                                7.486129,
                                46.967902
                            ],
                            [
                                7.485624,
                                46.966794
                            ],
                            [
                                7.486345,
                                46.966473
                            ],
                            [
                                7.485744,
                                46.965872
                            ],
                            [
                                7.485769,
                                46.965674
                            ],
                            [
                                7.485704,
                                46.96542
                            ],
                            [
                                7.485524,
                                46.965217
                            ],
                            [
                                7.485796,
                                46.964683
                            ],
                            [
                                7.485442,
                                46.964587
                            ],
                            [
                                7.485083,
                                46.964499000000007
                            ],
                            [
                                7.48472,
                                46.964419
                            ],
                            [
                                7.484354,
                                46.964345
                            ],
                            [
                                7.483986,
                                46.964278
                            ],
                            [
                                7.483616,
                                46.964217
                            ],
                            [
                                7.483243,
                                46.964163
                            ],
                            [
                                7.482869,
                                46.964116
                            ],
                            [
                                7.482069,
                                46.964029
                            ],
                            [
                                7.481674,
                                46.963982
                            ],
                            [
                                7.48135,
                                46.963937
                            ],
                            [
                                7.481069,
                                46.96389
                            ],
                            [
                                7.480797,
                                46.963829
                            ],
                            [
                                7.480528,
                                46.963757
                            ],
                            [
                                7.480228,
                                46.96366
                            ],
                            [
                                7.479877,
                                46.963538
                            ],
                            [
                                7.479021,
                                46.963221
                            ],
                            [
                                7.478952,
                                46.963102
                            ],
                            [
                                7.478472,
                                46.962905
                            ],
                            [
                                7.478659,
                                46.962767
                            ],
                            [
                                7.478798,
                                46.962652
                            ],
                            [
                                7.478706,
                                46.962419
                            ],
                            [
                                7.479148,
                                46.962125
                            ],
                            [
                                7.47963,
                                46.961666
                            ],
                            [
                                7.479791,
                                46.961481
                            ],
                            [
                                7.48011,
                                46.960991
                            ],
                            [
                                7.480287,
                                46.960677
                            ],
                            [
                                7.479503,
                                46.960385
                            ],
                            [
                                7.479449,
                                46.960396
                            ],
                            [
                                7.479603,
                                46.960201
                            ],
                            [
                                7.479694,
                                46.960068
                            ],
                            [
                                7.479583,
                                46.960032
                            ],
                            [
                                7.479565,
                                46.960049
                            ],
                            [
                                7.479539,
                                46.960059
                            ],
                            [
                                7.479508,
                                46.960062
                            ],
                            [
                                7.479477,
                                46.960055
                            ],
                            [
                                7.479201,
                                46.959878
                            ],
                            [
                                7.479513,
                                46.959653
                            ],
                            [
                                7.479499,
                                46.959454
                            ],
                            [
                                7.479442,
                                46.959148
                            ],
                            [
                                7.479349,
                                46.958903
                            ],
                            [
                                7.479324,
                                46.958866
                            ],
                            [
                                7.478931,
                                46.958785
                            ],
                            [
                                7.479502,
                                46.957115
                            ],
                            [
                                7.479749,
                                46.956817
                            ],
                            [
                                7.479779,
                                46.956602
                            ],
                            [
                                7.479645,
                                46.956405
                            ],
                            [
                                7.479593,
                                46.956347
                            ],
                            [
                                7.479437,
                                46.956188
                            ],
                            [
                                7.479111,
                                46.956027
                            ],
                            [
                                7.478664,
                                46.955917
                            ],
                            [
                                7.478593,
                                46.955688
                            ],
                            [
                                7.476902,
                                46.955524
                            ],
                            [
                                7.477025,
                                46.955332
                            ],
                            [
                                7.477182,
                                46.955121
                            ],
                            [
                                7.477057,
                                46.955077
                            ],
                            [
                                7.476927,
                                46.954836
                            ],
                            [
                                7.47692,
                                46.954815
                            ],
                            [
                                7.47692,
                                46.954766
                            ],
                            [
                                7.477014,
                                46.954459
                            ],
                            [
                                7.477025,
                                46.954443
                            ],
                            [
                                7.477042,
                                46.954432
                            ],
                            [
                                7.477066,
                                46.954426
                            ],
                            [
                                7.477091,
                                46.954426
                            ],
                            [
                                7.477221,
                                46.954125
                            ],
                            [
                                7.478037,
                                46.954189
                            ],
                            [
                                7.478126,
                                46.954141
                            ],
                            [
                                7.478578,
                                46.953918
                            ],
                            [
                                7.478924,
                                46.953773
                            ],
                            [
                                7.479227,
                                46.953588
                            ],
                            [
                                7.47938,
                                46.953563
                            ],
                            [
                                7.479541,
                                46.953465
                            ],
                            [
                                7.480014,
                                46.953192
                            ],
                            [
                                7.479999,
                                46.953156
                            ],
                            [
                                7.480983,
                                46.952525
                            ],
                            [
                                7.481035,
                                46.952532
                            ],
                            [
                                7.481607,
                                46.952114
                            ],
                            [
                                7.482241,
                                46.951588
                            ],
                            [
                                7.482687,
                                46.951102
                            ],
                            [
                                7.482978,
                                46.95091
                            ],
                            [
                                7.483081,
                                46.950802
                            ],
                            [
                                7.483253,
                                46.950521
                            ],
                            [
                                7.483391,
                                46.950367
                            ],
                            [
                                7.483648,
                                46.950258
                            ],
                            [
                                7.483821,
                                46.950119
                            ],
                            [
                                7.484051,
                                46.949994
                            ],
                            [
                                7.48332,
                                46.94771
                            ],
                            [
                                7.483911,
                                46.947553
                            ],
                            [
                                7.483683,
                                46.946724
                            ],
                            [
                                7.483643,
                                46.946473
                            ],
                            [
                                7.483621,
                                46.945786
                            ],
                            [
                                7.483563,
                                46.945555
                            ],
                            [
                                7.483393,
                                46.945198
                            ],
                            [
                                7.483494,
                                46.945166
                            ],
                            [
                                7.484982,
                                46.944613
                            ],
                            [
                                7.485232,
                                46.945091
                            ],
                            [
                                7.485283,
                                46.945012
                            ],
                            [
                                7.485659,
                                46.944687
                            ],
                            [
                                7.485746,
                                46.944574
                            ],
                            [
                                7.486022,
                                46.944315
                            ],
                            [
                                7.486107,
                                46.94427
                            ],
                            [
                                7.486236,
                                46.944268
                            ],
                            [
                                7.48691,
                                46.94405
                            ],
                            [
                                7.487266,
                                46.943955
                            ],
                            [
                                7.487412,
                                46.943926
                            ],
                            [
                                7.487563,
                                46.943911
                            ],
                            [
                                7.488001,
                                46.943825
                            ],
                            [
                                7.488262,
                                46.943714
                            ],
                            [
                                7.488486,
                                46.943581
                            ],
                            [
                                7.488721,
                                46.943415
                            ],
                            [
                                7.488891,
                                46.943236
                            ],
                            [
                                7.489409,
                                46.942765
                            ],
                            [
                                7.489661,
                                46.942636
                            ],
                            [
                                7.490198,
                                46.94231
                            ],
                            [
                                7.490749,
                                46.941845
                            ],
                            [
                                7.491427,
                                46.942566
                            ],
                            [
                                7.491602,
                                46.942386
                            ],
                            [
                                7.491801,
                                46.942205
                            ],
                            [
                                7.492209,
                                46.941878
                            ],
                            [
                                7.492478,
                                46.941633
                            ],
                            [
                                7.49263,
                                46.9415
                            ],
                            [
                                7.492786,
                                46.941376
                            ],
                            [
                                7.493093,
                                46.941145
                            ],
                            [
                                7.493778,
                                46.940669
                            ],
                            [
                                7.494157,
                                46.940425
                            ],
                            [
                                7.494403,
                                46.940274
                            ],
                            [
                                7.494831,
                                46.940021
                            ],
                            [
                                7.495154,
                                46.939838
                            ],
                            [
                                7.495558,
                                46.939617
                            ],
                            [
                                7.495541,
                                46.939562
                            ],
                            [
                                7.495226,
                                46.939035
                            ],
                            [
                                7.494949,
                                46.938621
                            ],
                            [
                                7.494373,
                                46.93882
                            ],
                            [
                                7.493513,
                                46.937659
                            ],
                            [
                                7.493045,
                                46.938113
                            ],
                            [
                                7.492981,
                                46.938169
                            ],
                            [
                                7.492903,
                                46.938227
                            ],
                            [
                                7.492826,
                                46.938275
                            ],
                            [
                                7.492734,
                                46.938324
                            ],
                            [
                                7.492647,
                                46.938362
                            ],
                            [
                                7.492513,
                                46.938409
                            ],
                            [
                                7.492372,
                                46.938446
                            ],
                            [
                                7.490805,
                                46.938762
                            ],
                            [
                                7.489771,
                                46.93737
                            ],
                            [
                                7.489709,
                                46.937275
                            ],
                            [
                                7.489642,
                                46.93716
                            ],
                            [
                                7.489501,
                                46.936867
                            ],
                            [
                                7.488621,
                                46.937014
                            ],
                            [
                                7.487741,
                                46.9371
                            ],
                            [
                                7.487028,
                                46.937138
                            ],
                            [
                                7.486472,
                                46.937157
                            ],
                            [
                                7.485765,
                                46.937218
                            ],
                            [
                                7.485986,
                                46.936658
                            ],
                            [
                                7.483599,
                                46.937071
                            ],
                            [
                                7.482251,
                                46.937147
                            ],
                            [
                                7.481587,
                                46.937154
                            ],
                            [
                                7.481347,
                                46.937197
                            ],
                            [
                                7.48067,
                                46.937374
                            ],
                            [
                                7.480361,
                                46.937417
                            ],
                            [
                                7.479789,
                                46.937461
                            ],
                            [
                                7.479191,
                                46.937483
                            ],
                            [
                                7.478979,
                                46.937459
                            ],
                            [
                                7.478761,
                                46.93741
                            ],
                            [
                                7.4784,
                                46.937296
                            ],
                            [
                                7.478014,
                                46.937201
                            ],
                            [
                                7.47743,
                                46.937108
                            ],
                            [
                                7.476668,
                                46.93695
                            ],
                            [
                                7.476635,
                                46.936923
                            ],
                            [
                                7.476636,
                                46.936888
                            ],
                            [
                                7.47811,
                                46.936249
                            ],
                            [
                                7.477565,
                                46.935819
                            ],
                            [
                                7.477236,
                                46.935535
                            ],
                            [
                                7.476917,
                                46.935182
                            ],
                            [
                                7.476853,
                                46.935069
                            ],
                            [
                                7.4768,
                                46.935112
                            ],
                            [
                                7.476776,
                                46.935117
                            ],
                            [
                                7.476752,
                                46.935113
                            ],
                            [
                                7.475776,
                                46.934636
                            ],
                            [
                                7.476041,
                                46.934391
                            ],
                            [
                                7.47546,
                                46.934184
                            ],
                            [
                                7.4747,
                                46.933919
                            ],
                            [
                                7.474766,
                                46.933608
                            ],
                            [
                                7.475089,
                                46.933276
                            ],
                            [
                                7.475355,
                                46.93299
                            ],
                            [
                                7.473784,
                                46.932635
                            ],
                            [
                                7.473798,
                                46.932607
                            ],
                            [
                                7.473982,
                                46.931906
                            ],
                            [
                                7.473994,
                                46.93188
                            ],
                            [
                                7.470937,
                                46.92982
                            ],
                            [
                                7.470926,
                                46.929833
                            ],
                            [
                                7.470711,
                                46.929765
                            ],
                            [
                                7.46998,
                                46.929546
                            ],
                            [
                                7.469325,
                                46.929334
                            ],
                            [
                                7.469106,
                                46.929243
                            ],
                            [
                                7.469214,
                                46.929052
                            ],
                            [
                                7.469221,
                                46.928812
                            ],
                            [
                                7.469197,
                                46.928695
                            ],
                            [
                                7.470447,
                                46.92738
                            ],
                            [
                                7.471288,
                                46.925784
                            ],
                            [
                                7.470769,
                                46.925504
                            ],
                            [
                                7.46924,
                                46.926693
                            ],
                            [
                                7.464199,
                                46.929807
                            ],
                            [
                                7.463189,
                                46.930536
                            ],
                            [
                                7.462414,
                                46.931147
                            ],
                            [
                                7.462047,
                                46.931525
                            ],
                            [
                                7.461771,
                                46.931939
                            ],
                            [
                                7.460944,
                                46.933442
                            ],
                            [
                                7.46059,
                                46.933954
                            ],
                            [
                                7.460039,
                                46.934368
                            ],
                            [
                                7.459343,
                                46.934656
                            ],
                            [
                                7.458568,
                                46.934836
                            ],
                            [
                                7.457754,
                                46.934872
                            ],
                            [
                                7.456953,
                                46.934747
                            ],
                            [
                                7.45623,
                                46.934486
                            ],
                            [
                                7.453577,
                                46.933344
                            ],
                            [
                                7.453052,
                                46.933146
                            ],
                            [
                                7.452474,
                                46.933003
                            ],
                            [
                                7.451924,
                                46.93207
                            ],
                            [
                                7.451642,
                                46.932221
                            ],
                            [
                                7.451179,
                                46.932226
                            ],
                            [
                                7.451146,
                                46.932251
                            ],
                            [
                                7.451067,
                                46.932286
                            ],
                            [
                                7.45092,
                                46.932232
                            ],
                            [
                                7.450698,
                                46.932109
                            ],
                            [
                                7.450449,
                                46.93204
                            ],
                            [
                                7.450442,
                                46.93202
                            ],
                            [
                                7.450235,
                                46.932035
                            ],
                            [
                                7.450193,
                                46.931853
                            ],
                            [
                                7.449607,
                                46.931917
                            ],
                            [
                                7.449445,
                                46.931899
                            ],
                            [
                                7.448884,
                                46.931894
                            ],
                            [
                                7.44864,
                                46.931593
                            ],
                            [
                                7.448655,
                                46.931588
                            ],
                            [
                                7.447807,
                                46.930534
                            ],
                            [
                                7.447638,
                                46.93043
                            ],
                            [
                                7.44736,
                                46.930684
                            ],
                            [
                                7.447271,
                                46.930757
                            ],
                            [
                                7.447177,
                                46.930827
                            ],
                            [
                                7.447069,
                                46.930901
                            ],
                            [
                                7.446938,
                                46.93098
                            ],
                            [
                                7.4468,
                                46.931053
                            ],
                            [
                                7.446669,
                                46.931115
                            ],
                            [
                                7.446175,
                                46.931318
                            ],
                            [
                                7.445991,
                                46.931379
                            ],
                            [
                                7.445774,
                                46.931431
                            ],
                            [
                                7.445586,
                                46.93142
                            ],
                            [
                                7.445398,
                                46.931466
                            ],
                            [
                                7.445358,
                                46.931482
                            ],
                            [
                                7.445377,
                                46.931505
                            ],
                            [
                                7.444998,
                                46.931652
                            ],
                            [
                                7.444736,
                                46.931788
                            ],
                            [
                                7.444235,
                                46.932026
                            ],
                            [
                                7.443733,
                                46.932238
                            ],
                            [
                                7.443059,
                                46.932492
                            ],
                            [
                                7.442377,
                                46.932722
                            ],
                            [
                                7.441524,
                                46.933045
                            ],
                            [
                                7.441073,
                                46.933221
                            ],
                            [
                                7.440772,
                                46.933365
                            ],
                            [
                                7.440263,
                                46.933647
                            ],
                            [
                                7.440214,
                                46.933622
                            ],
                            [
                                7.43968,
                                46.933962
                            ],
                            [
                                7.439229,
                                46.934147
                            ],
                            [
                                7.438612,
                                46.934267
                            ],
                            [
                                7.437387,
                                46.934711
                            ],
                            [
                                7.437045,
                                46.934781
                            ],
                            [
                                7.436546,
                                46.934833
                            ],
                            [
                                7.436123,
                                46.934906
                            ],
                            [
                                7.4354189999999999,
                                46.934967
                            ],
                            [
                                7.435294,
                                46.934823
                            ],
                            [
                                7.434977,
                                46.934515
                            ],
                            [
                                7.434452,
                                46.933977
                            ],
                            [
                                7.434031,
                                46.933463
                            ],
                            [
                                7.433803,
                                46.933124
                            ],
                            [
                                7.435036,
                                46.932427
                            ],
                            [
                                7.435934,
                                46.931939
                            ],
                            [
                                7.436374,
                                46.93171
                            ],
                            [
                                7.436709,
                                46.931546
                            ],
                            [
                                7.437227,
                                46.931282
                            ],
                            [
                                7.437535,
                                46.931138
                            ],
                            [
                                7.437756,
                                46.931029
                            ],
                            [
                                7.437719,
                                46.930962
                            ],
                            [
                                7.437682,
                                46.930795
                            ],
                            [
                                7.437647,
                                46.930759
                            ],
                            [
                                7.437641,
                                46.930742
                            ],
                            [
                                7.437468,
                                46.930769
                            ],
                            [
                                7.437183,
                                46.930805
                            ],
                            [
                                7.436782,
                                46.930851
                            ],
                            [
                                7.435612,
                                46.930974
                            ],
                            [
                                7.434973,
                                46.931036
                            ],
                            [
                                7.432981,
                                46.931243
                            ],
                            [
                                7.432861,
                                46.93133
                            ],
                            [
                                7.432754,
                                46.931358
                            ],
                            [
                                7.432709,
                                46.931328
                            ],
                            [
                                7.432653,
                                46.931309
                            ],
                            [
                                7.432562,
                                46.931297
                            ],
                            [
                                7.43245,
                                46.931294
                            ],
                            [
                                7.432353,
                                46.9313
                            ],
                            [
                                7.432252,
                                46.931314
                            ],
                            [
                                7.43215,
                                46.931334
                            ],
                            [
                                7.43205,
                                46.931361
                            ],
                            [
                                7.431912,
                                46.931411
                            ],
                            [
                                7.431895,
                                46.931413
                            ],
                            [
                                7.431661,
                                46.931521
                            ],
                            [
                                7.431512,
                                46.931577
                            ],
                            [
                                7.43143,
                                46.931601
                            ],
                            [
                                7.431237,
                                46.931644
                            ],
                            [
                                7.431116,
                                46.931712
                            ],
                            [
                                7.43129,
                                46.931903
                            ],
                            [
                                7.431296,
                                46.931922
                            ],
                            [
                                7.431296,
                                46.931941
                            ],
                            [
                                7.431289,
                                46.931958
                            ],
                            [
                                7.431275,
                                46.931974
                            ],
                            [
                                7.431252,
                                46.931989
                            ],
                            [
                                7.431095,
                                46.93206
                            ],
                            [
                                7.430906,
                                46.932154
                            ],
                            [
                                7.430376,
                                46.932436
                            ],
                            [
                                7.430184,
                                46.932526
                            ],
                            [
                                7.429957,
                                46.932615
                            ],
                            [
                                7.429659,
                                46.932712
                            ],
                            [
                                7.429363,
                                46.932803
                            ],
                            [
                                7.429277,
                                46.932825
                            ],
                            [
                                7.429086,
                                46.932928
                            ],
                            [
                                7.428736,
                                46.933009
                            ],
                            [
                                7.42844,
                                46.933058
                            ],
                            [
                                7.427873,
                                46.933197
                            ],
                            [
                                7.42759,
                                46.933221
                            ],
                            [
                                7.427301,
                                46.933315
                            ],
                            [
                                7.426981,
                                46.933445
                            ],
                            [
                                7.425774,
                                46.934093
                            ],
                            [
                                7.425218,
                                46.934447
                            ],
                            [
                                7.424985,
                                46.934635
                            ],
                            [
                                7.42473,
                                46.934345
                            ],
                            [
                                7.424528,
                                46.934104
                            ],
                            [
                                7.424305,
                                46.933828
                            ],
                            [
                                7.423545,
                                46.934195
                            ],
                            [
                                7.42336,
                                46.933934
                            ],
                            [
                                7.423189,
                                46.933991
                            ],
                            [
                                7.42237,
                                46.934277
                            ],
                            [
                                7.422106,
                                46.934381
                            ],
                            [
                                7.42172,
                                46.934586
                            ],
                            [
                                7.421213,
                                46.934156
                            ],
                            [
                                7.417683,
                                46.93635
                            ],
                            [
                                7.417089,
                                46.936368
                            ],
                            [
                                7.416082,
                                46.936643
                            ],
                            [
                                7.414695,
                                46.936819
                            ],
                            [
                                7.414739,
                                46.93664
                            ],
                            [
                                7.414809,
                                46.936403
                            ],
                            [
                                7.414642,
                                46.936379
                            ],
                            [
                                7.41469,
                                46.936235
                            ],
                            [
                                7.414684,
                                46.93596
                            ],
                            [
                                7.414638,
                                46.935766
                            ],
                            [
                                7.414512,
                                46.935491
                            ],
                            [
                                7.414409,
                                46.935209
                            ],
                            [
                                7.414326,
                                46.934813
                            ],
                            [
                                7.414206,
                                46.934417
                            ],
                            [
                                7.414147,
                                46.934013
                            ],
                            [
                                7.41407,
                                46.934012
                            ],
                            [
                                7.413944,
                                46.933513
                            ],
                            [
                                7.413895,
                                46.933378
                            ],
                            [
                                7.413794,
                                46.933202
                            ],
                            [
                                7.413642,
                                46.932775
                            ],
                            [
                                7.413418,
                                46.932583
                            ],
                            [
                                7.413649,
                                46.932095
                            ],
                            [
                                7.413845,
                                46.931569
                            ],
                            [
                                7.411595,
                                46.931229
                            ],
                            [
                                7.411679,
                                46.930954
                            ],
                            [
                                7.410878,
                                46.930831
                            ],
                            [
                                7.410861,
                                46.93088
                            ],
                            [
                                7.410762,
                                46.930863
                            ],
                            [
                                7.410779,
                                46.930816
                            ],
                            [
                                7.409868,
                                46.930679
                            ],
                            [
                                7.409778,
                                46.930967
                            ],
                            [
                                7.409385,
                                46.930915
                            ],
                            [
                                7.408712,
                                46.9309
                            ],
                            [
                                7.408233,
                                46.93098
                            ],
                            [
                                7.40758,
                                46.930688
                            ],
                            [
                                7.407363,
                                46.930548
                            ],
                            [
                                7.40708,
                                46.930383
                            ],
                            [
                                7.40709,
                                46.928832
                            ],
                            [
                                7.407086,
                                46.928221
                            ],
                            [
                                7.407393,
                                46.927969
                            ],
                            [
                                7.407464,
                                46.927815
                            ],
                            [
                                7.407518,
                                46.927552
                            ],
                            [
                                7.407517,
                                46.927486
                            ],
                            [
                                7.407453,
                                46.927278
                            ],
                            [
                                7.407242,
                                46.926812
                            ],
                            [
                                7.407237,
                                46.92666
                            ],
                            [
                                7.407265,
                                46.926435
                            ],
                            [
                                7.407336,
                                46.926375
                            ],
                            [
                                7.4075,
                                46.926215
                            ],
                            [
                                7.407639,
                                46.92607
                            ],
                            [
                                7.407786,
                                46.92591
                            ],
                            [
                                7.407965,
                                46.925701
                            ],
                            [
                                7.408066,
                                46.92563
                            ],
                            [
                                7.408002,
                                46.925594
                            ],
                            [
                                7.407479,
                                46.925356
                            ],
                            [
                                7.407094,
                                46.925203
                            ],
                            [
                                7.406647,
                                46.925028
                            ],
                            [
                                7.406411,
                                46.924949
                            ],
                            [
                                7.406045,
                                46.924813
                            ],
                            [
                                7.405714,
                                46.924701
                            ],
                            [
                                7.405359,
                                46.924618
                            ],
                            [
                                7.404985,
                                46.924551
                            ],
                            [
                                7.404498,
                                46.92447
                            ],
                            [
                                7.403739,
                                46.924372
                            ],
                            [
                                7.403017,
                                46.924412
                            ],
                            [
                                7.402775,
                                46.924394
                            ],
                            [
                                7.402584,
                                46.924357
                            ],
                            [
                                7.402289,
                                46.924284
                            ],
                            [
                                7.402127,
                                46.9242
                            ],
                            [
                                7.401768,
                                46.92388
                            ],
                            [
                                7.401274,
                                46.923467
                            ],
                            [
                                7.401146,
                                46.923252
                            ],
                            [
                                7.400989,
                                46.923017
                            ],
                            [
                                7.400902,
                                46.922827
                            ],
                            [
                                7.400882,
                                46.922623
                            ],
                            [
                                7.401052,
                                46.922454
                            ],
                            [
                                7.40122,
                                46.922353
                            ],
                            [
                                7.401412,
                                46.922286
                            ],
                            [
                                7.401426,
                                46.922108
                            ],
                            [
                                7.401493,
                                46.921718
                            ],
                            [
                                7.40154,
                                46.921529
                            ],
                            [
                                7.401539,
                                46.921369
                            ],
                            [
                                7.401484,
                                46.921193
                            ],
                            [
                                7.400931,
                                46.921565
                            ],
                            [
                                7.400424,
                                46.921956
                            ],
                            [
                                7.399963,
                                46.922183
                            ],
                            [
                                7.399621,
                                46.92263
                            ],
                            [
                                7.399364,
                                46.922791
                            ],
                            [
                                7.398729,
                                46.923241
                            ],
                            [
                                7.398199,
                                46.923668
                            ],
                            [
                                7.397679,
                                46.924032
                            ],
                            [
                                7.397252,
                                46.924383
                            ],
                            [
                                7.396681,
                                46.924811
                            ],
                            [
                                7.396082,
                                46.925372
                            ],
                            [
                                7.395327,
                                46.925573
                            ],
                            [
                                7.394286,
                                46.926003
                            ],
                            [
                                7.393344,
                                46.926424
                            ],
                            [
                                7.392792,
                                46.926814
                            ],
                            [
                                7.392409,
                                46.927102
                            ],
                            [
                                7.392113,
                                46.927383
                            ],
                            [
                                7.391826,
                                46.927567
                            ],
                            [
                                7.391321,
                                46.927905
                            ],
                            [
                                7.390563,
                                46.92836
                            ],
                            [
                                7.390288,
                                46.928507
                            ],
                            [
                                7.390087,
                                46.92863
                            ],
                            [
                                7.389626,
                                46.928959
                            ],
                            [
                                7.389295,
                                46.929241
                            ],
                            [
                                7.389041,
                                46.929399
                            ],
                            [
                                7.38873,
                                46.929544
                            ],
                            [
                                7.388567,
                                46.929642
                            ],
                            [
                                7.38826,
                                46.929864
                            ],
                            [
                                7.387971,
                                46.930085
                            ],
                            [
                                7.387574,
                                46.930451
                            ],
                            [
                                7.386972,
                                46.930117
                            ],
                            [
                                7.386801,
                                46.930015
                            ],
                            [
                                7.38637,
                                46.929866
                            ],
                            [
                                7.386235,
                                46.929744
                            ],
                            [
                                7.386245,
                                46.929452
                            ],
                            [
                                7.386262,
                                46.929342
                            ],
                            [
                                7.386124,
                                46.929222
                            ],
                            [
                                7.385263,
                                46.928661
                            ],
                            [
                                7.384898,
                                46.928487
                            ],
                            [
                                7.384425,
                                46.928231
                            ],
                            [
                                7.384097,
                                46.928473
                            ],
                            [
                                7.383532,
                                46.928169
                            ],
                            [
                                7.383079,
                                46.927945
                            ],
                            [
                                7.382815,
                                46.927778
                            ],
                            [
                                7.382314,
                                46.927282
                            ],
                            [
                                7.382173,
                                46.927437
                            ],
                            [
                                7.382128,
                                46.927506
                            ],
                            [
                                7.382107,
                                46.927713
                            ],
                            [
                                7.382101,
                                46.927878
                            ],
                            [
                                7.382076,
                                46.92793
                            ],
                            [
                                7.381923,
                                46.928099
                            ],
                            [
                                7.381787,
                                46.928265
                            ],
                            [
                                7.381682,
                                46.928377
                            ],
                            [
                                7.381684,
                                46.928488
                            ],
                            [
                                7.381673,
                                46.92852
                            ],
                            [
                                7.381657,
                                46.928542
                            ],
                            [
                                7.381499,
                                46.928689
                            ],
                            [
                                7.381159,
                                46.928938
                            ],
                            [
                                7.380961,
                                46.92909
                            ],
                            [
                                7.380903,
                                46.929141
                            ],
                            [
                                7.380871,
                                46.929179
                            ],
                            [
                                7.38002,
                                46.929893
                            ],
                            [
                                7.380322,
                                46.930213
                            ],
                            [
                                7.380376,
                                46.930217
                            ],
                            [
                                7.380787,
                                46.930355
                            ],
                            [
                                7.38048,
                                46.930833
                            ],
                            [
                                7.380528,
                                46.930967
                            ],
                            [
                                7.380746,
                                46.931643
                            ],
                            [
                                7.380952,
                                46.932331
                            ],
                            [
                                7.38087,
                                46.932409
                            ],
                            [
                                7.380626,
                                46.932367
                            ],
                            [
                                7.380353,
                                46.932356
                            ],
                            [
                                7.380137,
                                46.932358
                            ],
                            [
                                7.380066,
                                46.932364
                            ],
                            [
                                7.379916,
                                46.932412
                            ],
                            [
                                7.37972,
                                46.93248
                            ],
                            [
                                7.379656,
                                46.932508
                            ],
                            [
                                7.379385,
                                46.932527
                            ],
                            [
                                7.378951,
                                46.93254
                            ],
                            [
                                7.378257,
                                46.932584
                            ],
                            [
                                7.377944,
                                46.931657
                            ],
                            [
                                7.376627,
                                46.931593
                            ],
                            [
                                7.375977,
                                46.931572
                            ],
                            [
                                7.375433,
                                46.9315
                            ],
                            [
                                7.374868,
                                46.931431
                            ],
                            [
                                7.374891,
                                46.932711
                            ],
                            [
                                7.374528,
                                46.932656
                            ],
                            [
                                7.374233,
                                46.932623
                            ],
                            [
                                7.373312,
                                46.932549
                            ],
                            [
                                7.373253,
                                46.932518
                            ],
                            [
                                7.373244,
                                46.932466
                            ],
                            [
                                7.373176,
                                46.932637
                            ],
                            [
                                7.372488,
                                46.932536
                            ],
                            [
                                7.371707,
                                46.932395
                            ],
                            [
                                7.370724,
                                46.932238
                            ],
                            [
                                7.370725,
                                46.932145
                            ],
                            [
                                7.369929,
                                46.931805
                            ],
                            [
                                7.369741,
                                46.931957
                            ],
                            [
                                7.369414,
                                46.932105
                            ],
                            [
                                7.369137,
                                46.93219
                            ],
                            [
                                7.368427,
                                46.9323
                            ],
                            [
                                7.368067,
                                46.932394
                            ],
                            [
                                7.367438,
                                46.931789
                            ],
                            [
                                7.368411,
                                46.930892
                            ],
                            [
                                7.368477,
                                46.930548
                            ],
                            [
                                7.368307,
                                46.930427
                            ],
                            [
                                7.368062,
                                46.930284
                            ],
                            [
                                7.367152,
                                46.929812
                            ],
                            [
                                7.366413,
                                46.929507
                            ],
                            [
                                7.366366,
                                46.92949
                            ],
                            [
                                7.365285,
                                46.929216
                            ],
                            [
                                7.364896,
                                46.929166
                            ],
                            [
                                7.364344,
                                46.929105
                            ],
                            [
                                7.36414,
                                46.929109
                            ],
                            [
                                7.363674,
                                46.92906
                            ],
                            [
                                7.363202,
                                46.92895
                            ],
                            [
                                7.362527,
                                46.928824
                            ],
                            [
                                7.36206,
                                46.928788
                            ],
                            [
                                7.360826,
                                46.928681
                            ],
                            [
                                7.360408,
                                46.928589
                            ],
                            [
                                7.360513,
                                46.927982
                            ],
                            [
                                7.360536,
                                46.92745
                            ],
                            [
                                7.36056,
                                46.927202
                            ],
                            [
                                7.359435,
                                46.927155
                            ],
                            [
                                7.358974,
                                46.927146
                            ],
                            [
                                7.358464,
                                46.92716
                            ],
                            [
                                7.357604,
                                46.927193
                            ],
                            [
                                7.357467,
                                46.927183
                            ],
                            [
                                7.357142,
                                46.927149
                            ],
                            [
                                7.356838,
                                46.927114
                            ],
                            [
                                7.356139,
                                46.927023
                            ],
                            [
                                7.355676,
                                46.926942
                            ],
                            [
                                7.35514,
                                46.926855
                            ],
                            [
                                7.354639,
                                46.926821
                            ],
                            [
                                7.354162,
                                46.926794
                            ],
                            [
                                7.353932,
                                46.926808
                            ],
                            [
                                7.353951,
                                46.926028
                            ],
                            [
                                7.353372,
                                46.926
                            ],
                            [
                                7.352188,
                                46.926025
                            ],
                            [
                                7.351997,
                                46.92601
                            ],
                            [
                                7.351063,
                                46.925891
                            ],
                            [
                                7.350331,
                                46.925773
                            ],
                            [
                                7.34959,
                                46.925558
                            ],
                            [
                                7.348936,
                                46.92547
                            ],
                            [
                                7.347995,
                                46.925243
                            ],
                            [
                                7.347042,
                                46.925177
                            ],
                            [
                                7.346443,
                                46.925173
                            ],
                            [
                                7.345976,
                                46.92516
                            ],
                            [
                                7.344988,
                                46.9251
                            ],
                            [
                                7.343535,
                                46.924856
                            ],
                            [
                                7.338784,
                                46.924537
                            ],
                            [
                                7.339369,
                                46.919668
                            ],
                            [
                                7.335302,
                                46.919455
                            ],
                            [
                                7.331228,
                                46.919235
                            ]
                        ]
                    ]
                ]
			}
		}
	]
}