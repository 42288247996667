import React from 'react';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import Badge from '@material-ui/core/Badge';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { getTaxon } from "../api/actions/index";



const myAnchor = {vertical: 'top', horizontal: 'right' };

function SplitButton(props) {
  
  //const options = props.menudata.map(obj => {return obj.latin});//['Create a merge commit', 'Squash and merge', 'Rebase and merge'];
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  //const [selectedIndex, setSelectedIndex] = React.useState(1);

  const handleClick = () => {
    ////console.log(`You clicked Item`);// ${options[selectedIndex]}
  };

  const handleItemClick = (event) => {
    ////console.log(event.nativeEvent.target.outerText);
    const { taxonLink, taxonId } = event.currentTarget.dataset;
    ////console.log(taxonLink)
    props.getTaxon(taxonId);
    props.history.push(taxonLink);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <Badge style={{margin:'10px'}} color="secondary" max={9999999} badgeContent={`${props.menudata.length}`} anchorOrigin={myAnchor}>          
            <ButtonGroup variant="contained" color="primary" ref={anchorRef} aria-label="split button">
            <Button onClick={handleClick}>{props.menudata[0].genusName}</Button>
            <Button
                color="primary"
                size="small"
                aria-controls={open ? 'split-button-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-label="select merge strategy"
                aria-haspopup="menu"
                onClick={handleToggle}
            >
                <ArrowDropDownIcon />
            </Button>
            </ButtonGroup>
        </Badge>
        <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex:'1500'}}>
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper style={{maxHeight:'300px', overflowY:'auto'}}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu">
                    {props.menudata.map((option, index) => (
                      <MenuItem
                        key={index}
                        //disabled={index === 2}
                        //selected={index === selectedIndex}
                        //onClick={(event) => handleMenuItemClick(event, index)}
                        onClick={handleItemClick} 
                        data-taxon-id={option.id}
                        data-taxon-link={"/flib/flora/" + option.id + "/" + option.latin.replace(/\s/g, "-")}
                      >
                        {option.latin}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </>
  );
}

function mapStateToProps(state) {
  //console.log("mapStateToProps",state);
  return {
    taxonRemoteData: state.remoteTaxon[0]
  };
}

export default withRouter(connect(
  mapStateToProps,
  { getTaxon }
)(SplitButton));
