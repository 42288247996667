import React, { Component } from "react";
import 'ol/ol.css';
import OlMap from "ol/Map";
import TileWMS from 'ol/source/TileWMS';
import WMTS from 'ol/tilegrid/WMTS';
import WMTSSOURCE from 'ol/source/WMTS';
import Vector from 'ol/layer/Vector';
import VectorSrc from 'ol/source/Vector';
import OlView from "ol/View";
import OlLayerTile from "ol/layer/Tile";
import {defaults as defaultControls, ScaleLine, FullScreen, ZoomToExtent} from 'ol/control';
import LayerSwitcher from 'ol-ext/control/LayerSwitcher';
import olOverlay from 'ol-ext/control/Overlay';

import olToggle from 'ol-ext/control/Toggle';
import 'ol-ext/control/LayerSwitcher.css';
import  Mask from 'ol-ext/filter/Mask'

import Projection from 'ol/proj/Projection';
import {register} from 'ol/proj/proj4';
import {get as getProjection, addProjection} from 'ol/proj'
import proj4 from 'proj4';
import GeoJSON from 'ol/format/GeoJSON';
import {Style, Stroke, Fill, Icon, Circle } from 'ol/style';
import { connect } from "react-redux";
import { getActuelYear } from "../api/actions/index";
import { withRouter } from 'react-router-dom';

import { polygon, point, inside } from '@turf/turf';


import * as CommunBernLimit from '../data/communberlimit';
import * as grid1x1kmBernCommun from '../data/grid1x1kmBernCommun';

import Button from '@material-ui/core/Button';
import ArtTrackIcon from '@material-ui/icons/ArtTrack';
import LayersIcon from '@material-ui/icons/Layers';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import Drawer from '@material-ui/core/Drawer';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import IconButton from '@material-ui/core/IconButton';


var arrCircle = ["I","A","ni"];
var arrSquareBlau = ["N","NC"];
var arrSquareBlack = ["IN"];
var arrSquareGreen = ["NC-reg"];
var arrRhombus = ["WE"];


const mZoom			= 12.762397961829938;
var myCenter = [596722.6269318011,199733.2292637022];
        
const extent = [588104.7441443924,193650.01788435495,605340.5097192097,205816.44064304946];//Canton Bern [511543.39245947637,127167.06484597642,727286.8674844417,251254.81518713298]// CantonBernExtent //[minX, minY, maxX, maxY];// [-1580000.03, -1970000.03, -99999.94, -649999.94]//CantonBernExtent;//[460856.5, 68463.8, 858656.2, 302479.1]//
const extentBern = [511543.39245947637,127167.06484597642,727286.8674844417,251254.81518713298];
var RESOLUTIONS = [4e3, 3750, 3500, 3250, 3e3, 2750, 2500, 2250, 2e3, 1750, 1500, 1250, 1e3, 750, 650, 500, 250, 100, 50, 20, 10, 5, 2.5, 2, 1.5, 1, .5, .25];
var defaultResolution = 20;
var defaultVectorExtension = ".svg";

/* function getCenterOfExtent(Extent){
  var X = Extent[0] + (Extent[2]-Extent[0])/2;
  var Y = Extent[1] + (Extent[3]-Extent[1])/2;
  return [X, Y];
} */

function kmpolygonTransStyle(){
  const style =  new Style({
    stroke: new Stroke({
        color: "rgb(204, 204, 255)",
        width: 2
    })
  });
  return style;
}

function kmpolygonPurpleStyle(){
  const style = new Style({
    fill: new Fill({
      color: [18,18,222,0.25]
    })
  });
  return style;
}

function kmpolygonBlackStyle (){
  const style = new Style({
    fill: new Fill({
      color: [0,0,0,0.4]
    })
  });
  return style;
}

function kmpolygonGreenStyle () {
  const style = new Style({
    fill: new Fill({
      color: [0,102,0,0.25]
    }),
  });
  return style;
}

function ptinsidepoly (pt,clr, gridFeatures){
 
  var gridPoly;

  gridFeatures.getFeatures().forEach(function(feature) {
    gridPoly = polygon(feature.getGeometry().getCoordinates());
    
    if(inside(pt, gridPoly)){
      switch (clr) {
        case 'green':
          feature.setStyle(kmpolygonGreenStyle());
          break;
        case 'purple':
          feature.setStyle(kmpolygonPurpleStyle());
          break;
        case 'grey':
          feature.setStyle(kmpolygonBlackStyle());
          break;
        default:
          feature.setStyle(kmpolygonTransStyle());
          break;
      }
      
    }
    
  });
}



function ResFunc(resolution){
  if(resolution >= defaultResolution-3){
    return defaultResolution/resolution;
  }else{
    if((3.5*(resolution/defaultResolution)) < 1.5){
      return 3.5;
    }else{
      return 3.5*(resolution/defaultResolution);
    }
  }
}


var flibCache = {};

function flibSymbolization (category, resolution){
  //console.log("flibSymbolization category", category);
  switch (category) {
      case 1001:
          return new Style({
              image: new Circle({
                  radius: 3.5 * defaultResolution / resolution,
                  fill: new Fill({
                      color: [255, 40, 0, 1]
                  })
              })
          });
      case 1002:
          return new Style({
              image: new Circle({
                  radius: 5 * defaultResolution / resolution,
                  fill: new Fill({
                      color: [255, 0, 0, 1]
                  })
              })
          });
      case "transparent":
          return new Style({
              image: new Icon({
                  scale: ResFunc(resolution)+0.8,
                  src: "/assets/images/atlas/atlas-trans.png"
              })
          });
      case "purple":

          return new Style({
              image: new Icon({
                  scale: ResFunc(resolution)+0.8,
                  src: "/assets/images/atlas/atlas-trans.png"
              })
          });
      case "grey":

          return new Style({
              image: new Icon({
                  scale: ResFunc(resolution)+0.8,
                  src: "/assets/images/atlas/atlas-trans.png"
              })
          });
      default:

        return new Style({
                      image: new Icon({
                          scale: ResFunc(resolution)+0.5,
                          src: "/assets/images/atlas/atlas-" + category + defaultVectorExtension
                      })
                  })
  }
}

function featureToCategory(feature, reg_protection,taxonYear, gridFeatures ){
            
  var category = 0;
  /* var doubt = feature.get("doubt");
  var introduction = feature.get("introduction_status");
  var validation = feature.get("validation_status");
  var obskm = feature.get("xy_precision");
  var obs_nb = feature.get("obs_nb"); */
  var obskm = feature.get("xy_precision");
  var obs_year = feature.get("obs_year");
  var pt = point(feature.getGeometry().getCoordinates());
 
    if(arrCircle.includes(reg_protection)){
      if(obskm === 707){
        ptinsidepoly(pt,'green',gridFeatures);
        category = "transparent"
      }else{
        category = 201;
      }
      
    } else if(arrSquareGreen.includes(reg_protection)){
      if(obskm === 707){
        ptinsidepoly(pt,'green',gridFeatures);
        category = "transparent"
      }
      else {
        category = 221;
      }
      
    } else if (arrSquareBlau.includes(reg_protection)){
      if (obskm === 707) {
        ptinsidepoly(pt, 'purple', gridFeatures);
        category = "transparent"
      } else {
        category = 222;
      }
    } else if (arrSquareBlack.includes(reg_protection)){
      if (obskm === 707) {
        ptinsidepoly(pt, 'grey', gridFeatures);
        category = "transparent"
      } else {
        category = 223;
      }
    } else if (arrRhombus.includes(reg_protection)){
      if (obskm === 707) {
        ptinsidepoly(pt, 'green', gridFeatures);
        category = "transparent"
      } else {
        category = 213;
      }
    } else {
      category = 0;
    }
      

  if (category > 0) {
      
      if (obs_year < taxonYear) {
         // if (obs_year >= props.taxonYear) {
              category -= 200;
         // }
      } else if (obs_year > taxonYear) {
          category -= 100;
      }
  }

  return category
}

let taxonDefaultYear, regProtection; 

var grid1x1kmBernfeatures = new GeoJSON().readFeatures(grid1x1kmBernCommun.grid1x1kmBernCommun, {
  dataProjection: "EPSG:21781",
  featureProjection: "EPSG:21781"
}); 

var grid1x1kmBernSrc = new VectorSrc({
  features: grid1x1kmBernfeatures
});


class FlibMap extends Component {
  constructor(props) {
    super(props);
    this.state={
      taxonDefaultYear: props.taxonDefaultYear.year
    }


    proj4.defs('EPSG:21781',
      '+proj=somerc +lat_0=46.95240555555556 +lon_0=7.439583333333333 +k_0=1 ' +
      '+x_0=600000 +y_0=200000 +ellps=bessel ' +
      '+towgs84=660.077,13.551,369.344,2.484,1.783,2.939,5.66 +units=m +no_defs');

    register(proj4);
    const swissProjection = getProjection('EPSG:21781');

    addProjection(swissProjection);

    var projection = new Projection({
      code: 'EPSG:21781',
      extent: [485869.5728, 76443.1884, 837076.5648, 299941.7864]
    });


      var chtopoAttributions = {
        html: '&copy; <a target="_blank" href="https://www.geo.admin.ch/internet/geoportal/en/home.html">Pixel maps / geo.admin.ch</a>'
      };
      var ifAttributions = {
          html: ' &copy; <a target="_blank" href="https://www.infoflora.ch/">Distribution maps / Info Flora</a><br>'
      };
       var devAttributions = {
          html: '&copy; <a target="_blank" href="https://www.bern.ch/politik-und-verwaltung/stadtverwaltung/tvs/stadtgrun-bern">Daten / Stadtgrün Stadt bern</a> &copy; <a target="_blank" href="https://www.its-4you.ch/">Entwicklung / iTs4USwiss</a>'
      };
      
      var wmtsSource = function(layer, options) {
        var resolutions = options.resolutions ? options.resolutions : RESOLUTIONS;
        var tileGrid = new WMTS({
            origin: [42e4, 35e4],
            resolutions: resolutions,
            matrixIds: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27]
        });
        var extension = options.format || "png";
        var timestamp = options.timestamps;
        return new WMTSSOURCE({
            crossOrigin: "anonymous",
            attributions: chtopoAttributions.html + ifAttributions.html + devAttributions.html,
            url: (`https://wmts{5-9}.geo.admin.ch/1.0.0/{Layer}/default/${timestamp}/21781/{TileMatrix}/{TileRow}/{TileCol}.`).replace("http:", window.location.protocol) + extension,
            projection: projection,
            tileGrid: tileGrid,
            layer: options.serverLayerName ? options.serverLayerName : layer,
            requestEncoding: "REST"
        })
      };

      var wmsCadastre = new OlLayerTile({
        extent: extent,
        opacity: 0.50,
        title: 'Hintergrund Karte',
        source: new TileWMS({
          url: 'https://wms.geo.admin.ch/',
          crossOrigin: 'anonymous',
          params: {
            'LAYERS': 'ch.swisstopo-vd.amtliche-vermessung',
            'FORMAT': 'image/png',
            'TILED': false,
            'timeEnabled': false,
            'VERSION': '1.1.1'
          },
          serverType: 'mapserver'
        })
      });
      
      var photoLayer = new OlLayerTile({
              source: wmtsSource("ch.swisstopo.swissimage", {
                  timestamps: ["current"],
                  format: "jpeg"
              }),
        opacity:0.25,
        title: 'Hintergrund Luftbild',
        name: "photo"
      });

     /* var cantonBorderLayer = new OlLayerTile({
        source: wmtsSource("ch.swisstopo.swissboundaries3d-kanton-flaeche.fill", {
            timestamps: ["current"],
            format: "png"
        }),
        opacity:0.25,
        title: 'swissBOUNDARIES3D Kantonsgrenzen',
        name: "Kantonsgrenzen"
      });


      var pkTlm3dLayer = new OlLayerTile({
              source: wmtsSource("ch.swisstopo.swisstlm3d-karte-farbe", {
                  timestamps: [20150401],
                  format: "png"
              }),
        opacity:0.25,
        title: 'Swisstlm3d karte farbe',
              name: "pktlm3dAlt"
      });

    var pkColorLayer = new OlLayerTile({
        source: wmtsSource("ch.swisstopo.pixelkarte-farbe", {
            timestamps:  ["current"],//[20151231],
            format: "jpeg"
        }),
        opacity:0.50,
        title: 'Hintergrund Karte',
        name: "pkcolor"
    });

    var pkAlti3dLayer = new OlLayerTile({
        source: wmtsSource("ch.swisstopo.swissalti3d-reliefschattierung", {
            timestamps: [20160101],
            format: "png"
        }),
        opacity:0.5,
        title: 'Swisstopo Swissalti3d-reliefschattierung',
              name: "pkalti3d"
    });

    var pkHydroLayer = new OlLayerTile({
        source: wmtsSource("ch.bafu.ren-fliessgewaesser_seen", {
            timestamps: [20110214],
            format: "png"
        }),
        opacity:0.5,
        title: 'ch.bafu.ren-fliessgewaesser_seen',
        name: "pkhydro"
    }); */


/* var BernLimitLayer = new Vector({
  openInLayerSwitcher: true,
  source: BernLimitSrc,
  style: new Style({
    stroke: new Stroke({
        color: 'rgb(204, 204, 153)',
        width: 3
    })
  }),
  title: 'Kanton Bern grenzen',
      name: "BernLimitLayer"
}); */


var communBernfeatures = new GeoJSON().readFeatures(CommunBernLimit.communBernLimit, {
  dataProjection: "EPSG:4326",
  featureProjection: "EPSG:21781"
}); 

var communBernSrc = new VectorSrc({
  features: communBernfeatures
});

var communBernLayer = new Vector({
  openInLayerSwitcher: false,
  source: communBernSrc,
  style: new Style({
    stroke: new Stroke({
        color: "red",
        width: 2
    })
  }),
  title: 'Gemeinde Bern grenzen',
      name: "bernlimit"
});



var grid1x1kmBernLayer = new Vector({
  openInLayerSwitcher: true,
  source: grid1x1kmBernSrc,
  style:kmpolygonTransStyle,
  title: 'Kilometerraster',
      name: "grid1x1km"
});

//console.log(communBernfeatures);

var mask = new Mask({ feature: communBernfeatures[0], inner:false, fill: new Fill({ color:[255,255,255,0.8] }) });
communBernLayer.addFilter(mask);

/* var reliefLayer = new Group({
    layers: [pkAlti3dLayer, pkHydroLayer], //, bordersLayer
    name: "relief"
}); */


  //console.log("Pros constructor ", this.props.observation.taxonRemoteData);

  var flibKmLayer = new Vector({
    source: new VectorSrc({
      features:[],
    }),
    //style:flibStyle,
    openInLayerSwitcher: false,
    projection: swissProjection,
    title: "Grobdaten (1x1 Km)",
    name: "flib1x1km"
  });

  var flibPtLayer = new Vector({
    source: new VectorSrc({
      features:[],
    }),
    //style:flibStyle,
    projection: swissProjection,
    title: "Punktdaten",
    name: "flibpoint"
  });
  
  /* var flibGrpLayer = new Group({
    layers: [flibKmGrpLayer, flibPtLayer],
    openInLayerSwitcher: true,
    title: "Beobachtungen Gemeinde Bern",
    name: "flibobs"
    }); */

  //pkTlm3dLayer.setVisible(false);
  photoLayer.setVisible(true);
  //reliefLayer.setVisible(false);

    var olmap = new OlMap({
      controls: defaultControls().extend([
        new ScaleLine(),
        new FullScreen(), 
				new ZoomToExtent({
						extent: extent
          }),
        //new ZoomSlider()
				//new LayerSwitcher(), 
      ]),
      layers: [photoLayer, wmsCadastre, communBernLayer, grid1x1kmBernLayer, flibKmLayer, flibPtLayer], // pkColorLayer, pkTlm3dLayer, photoLayer, cantonBorderLayer, BernLimitLayer, ******* cantonBorderLayer, grid1kmLayer vectorLayer,, lakesLayer,  simpleLayer, flibLayer , grid1kmLayer, gemeindbernbordersLayer
      renderer: "canvas",
      target: "flibMap",
      view: new OlView({
        projection: swissProjection,
        center: [619415.129971959,189210.9400165547],//fromLonLat([7.439583,46.952406], swissProjection),//fromLonLat([46.894, 7.4405], projection),//getCenterOfExtent(extent),//fromLonLat([46.94337, 7.56538], swissProjection),//fromLonLat([12.5, 41.883299999999998], swissProjection),//getCenterOfExtent(CantonBernExtent),//this.state.center,//[0,0],//fromLonLat([12.5, 41.883299999999998], projection),//getCenterOfExtent(CantonBernExtent),//fromLonLat([12.5, 41.883299999999998], projection),//
        extent: extent,
        resolution: defaultResolution,
        resolutions: RESOLUTIONS,
				zoom: mZoom
      })
    });

    // Add control inside the map
    var ctrl = new LayerSwitcher();
    olmap.addControl(ctrl);
    ctrl.on('toggle', function(e) {
      //console.log('Collapse layerswitcher', e.collapsed);
    });


    this.anchorRef = React.createRef();

    this.state = { 
      direction:'ltr',
      open:false,
      openLeft:false,
      selectedIndex:0,
      map: olmap,
      extent: extent,
      grid1x1kmBernLayer: grid1x1kmBernLayer,
      flibKmLayer : flibKmLayer,
      flibPtLayer : flibPtLayer,
      projection: swissProjection,
      center: myCenter,//getCenterOfExtent(extent), //[7.439583,46.952406],//
      zoom: mZoom,
      taxonRemoteData: this.props.taxonRemoteData !== undefined ? this.props.taxonRemoteData : [],
      TaxonInfos: Object.keys(this.props.observation.taxonRemoteData.TaxonAttributsInfos).length > 0 ? this.props.observation.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos: []
    };

  }

  
    loadFlib(){

      taxonDefaultYear = this.props.taxonYear;
      regProtection =  Object.keys(this.props.observation.taxonRemoteData.TaxonAttributsInfos).length > 0 ? this.props.observation.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.reg_protection : "";

      this.state.flibKmLayer.getSource().clear();
      this.state.flibPtLayer.getSource().clear();
      
      const myKmSrc = {
        "type":"FeatureCollection",
        "features": this.props.observation.taxonRemoteData.taxonFeatures.filter(ft => ft.properties.xy_precision === 707)
      };

      const myPtSrc = {
        "type":"FeatureCollection",
        "features": this.props.observation.taxonRemoteData.taxonFeatures.filter(ft => ft.properties.xy_precision !== 707)
      };

      var flibKmfeatures = new GeoJSON({ignoreExtraDims: true}).readFeatures(myKmSrc); 

      var flibPtfeatures = new GeoJSON({ignoreExtraDims: true}).readFeatures(myPtSrc); 

      if(Object.keys(this.props.observation.taxonRemoteData.TaxonAttributsInfos).length > 0){

        this.state.flibKmLayer.setSource(
          new VectorSrc({
            features: flibKmfeatures
          })
        );
        this.state.flibPtLayer.setSource(
          new VectorSrc({
            features: flibPtfeatures
          })
        );
        this.state.grid1x1kmBernLayer.setSource(
          new VectorSrc({
            features: grid1x1kmBernfeatures
          })
        );

        this.state.flibKmLayer.setStyle(this.flibStyle);
        this.state.flibPtLayer.setStyle(this.flibStyle);

      }
      
    }

    flibStyle(feature, resolution){
         
        var category = featureToCategory(feature, regProtection, taxonDefaultYear, grid1x1kmBernSrc);
      
          if (!flibCache[category]) {
              flibCache[category] = {}
          }
          if (!flibCache[category][resolution]) {
              flibCache[category][resolution] = [flibSymbolization(category, resolution)]
      
          }
          return flibCache[category][resolution]
    }
  

  updateMap() {
    this.state.map.getView().setZoom(this.state.zoom);
  }


  componentDidMount() {

    var menu = new olOverlay ({ 
      closeBox : true, 
      className: "slide-left menu", 
      content: document.getElementById("menu").innerHTML
    });
    this.state.map.addControl(menu);

    var switcher = new LayerSwitcher({

      target: document.getElementsByClassName("layerSwitcher")[0], 
      show_progress:true,
      
    });

    this.state.map.addControl(switcher);
  
    // A toggle control to show/hide the menu
    var t = new olToggle(
        {	html: '<i class="fa fa-info" ></i>',
          className: "menu",
          title: "Legende",
          onToggle: function() { menu.toggle(); }
    });
    this.state.map.addControl(t);

    this.state.map.setTarget("map");


    // Listen to map changes
    /* var currZoom = this.state.map.getView().getZoom();
    this.state.map.on("moveend", () => {
      let center = this.state.map.getView().getCenter();
      let zoom = this.state.map.getView().getZoom();
      //this.setState({ center, zoom });
      //console.log('Centre: ' + center);
      //console.log('Extent: ' + this.state.map.getView().calculateExtent(this.state.map.getSize()));
      var newZoom = this.state.map.getView().getZoom();
      if (currZoom != newZoom) {
        //console.log('zoom end, new zoom: ' + newZoom);
        currZoom = newZoom;
      }
    }); */

    this.loadFlib()
    
  }

  componentDidUpdate(prevProps, prevState) {

    this.state.grid1x1kmBernLayer.getSource().getFeatures().forEach(function(feature) {
      feature.setStyle(kmpolygonTransStyle());
    });

    this.loadFlib();
    
  }

  shouldComponentUpdate(nextProps, nextState) {
    //let center = this.state.map.getView().getCenter();
    //let zoom = this.state.map.getView().getZoom();
    this.state.flibKmLayer.getSource().dispatchEvent("change");
    this.state.flibPtLayer.getSource().dispatchEvent("change");
    return true;
  }

  userAction() {
    
    this.state.map.setView(new OlView({
      center: this.state.map.getView().getCenter(),
      extent: extentBern,
      zoom: this.state.map.getView().getZoom()
    }));
    this.setState({ center: [546000, 6868000], zoom: 5 });

  }

 

  render() {

    this.updateMap(); 

    const handleToggle = () =>{
      this.setState({open: true});
      document.getElementById("CarteSetting").classList.remove("d-none");
    }
    const handleToggleLeft = () =>{
      this.setState({openLeft: true});
    }

    const handleDrawerOpen = () => {
      this.setState({open: true});
      document.getElementById("CarteSetting").classList.remove("d-none");
    };
    const handleDrawerOpenLeft = () => {
      this.setState({openLeft: true});
    };
    const handleDrawerClose = (event) => {
      if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
        return;
      }
      this.setState({open: false});
      document.getElementById("CarteSetting").classList.add("d-none");
    };
    const handleDrawerCloseLeft = (event) => {
      this.setState({openLeft: false});
    };
    
    return (
      <>
        <ButtonGroup className="float-md-right mb-4" variant="contained" color="primary" ref={this.anchorRef} aria-label="split button">
          <Button onClick={handleDrawerOpen}>Karte Einstellungen</Button>
          <Button
            color="primary"
            size="small"
            onClick={handleToggle}
          >
            <LayersIcon />
          </Button>
        </ButtonGroup>
        <ButtonGroup className="float-md-left mb-4" variant="contained" color="primary" ref={this.anchorRef} aria-label="split button">
          <Button
              color="primary"
              size="small"
              onClick={handleToggleLeft}
            >
            <ArtTrackIcon />
          </Button>
          <Button onClick={handleDrawerOpenLeft}>LEGENDE</Button>
        </ButtonGroup>
        <Drawer
            variant="persistent"
            anchor="right"
            open={this.state.open}
          >
          <div >
            <IconButton onClick={handleDrawerClose}>
              {this.state.direction === 'rtl' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
          <div id="CarteSetting" className="external layerSwitcher d-none"><center><b>Karte Einstellungen</b></center></div>
        </Drawer>
        <Drawer
            variant="persistent"
            anchor="left"
            open={this.state.openLeft}
          >
          <div >
            <IconButton style={{float:'right'}} onClick={handleDrawerCloseLeft}>
              {this.state.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </div>
          <Divider />
           <div className="panel-body">

            <h4>Einfach</h4>
            <table className="table">
                <tbody><tr>
                    <td>
                      <img alt="" src="/assets/images/atlas/atlas-1.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-21.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-22.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-23.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-13.svg"/>
                    </td>
                    <td>Vor gewähltem Stichjahr</td>
                </tr>
                <tr>
                    <td>
                      <img alt="" src="/assets/images/atlas/atlas-101.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-121.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-122.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-123.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-113.svg"/>
                    </td>
                    <td>Nach gewähltem Stichjahr</td>
                </tr>
                <tr>
                    <td>
                      <img alt="" src="/assets/images/atlas/atlas-201.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-221.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-222.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-223.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-213.svg"/>
                    </td>
                    <td>Vor und nach Stichjahr</td>
                </tr>
            </tbody>
            </table>
            <h4>Erweitert</h4>
            <table className="table">
                <tbody><tr>
                    <th colSpan="2">Farbe der Symbole</th>
                </tr>
                <tr>
                    <td className="text-center">
                      <img alt="" src="/assets/images/atlas/atlas-1.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-101.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-201.svg"/>
                    </td>
                    <td>Einheimisch</td>
                </tr>
                <tr>
                    <td className="text-center">
                      <img alt="" src="/assets/images/atlas/atlas-21.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-121.svg"/>
                      <img alt="" src="/assets/images/atlas/atlas-221.svg"/>
                    </td>
                    <td>Regional eingeführt</td>
                </tr>
                <tr>
                  <td className="text-center">
                    <img alt="" src="/assets/images/atlas/atlas-22.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-122.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-222.svg"/>
                  </td>
                  <td>Eingeführt</td>
                </tr>
                <tr>
                  <td className="text-center">
                    <img alt="" src="/assets/images/atlas/atlas-23.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-123.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-223.svg"/>
                  </td>
                  <td>Invasiver Neophyt</td>
                </tr>
                <tr>
                  <td className="text-center">
                    <img alt="" src="/assets/images/atlas/atlas-13.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-113.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-213.svg"/>
                  </td>
                  <td>Wiederangesiedelt, einheimisch</td>
                </tr>
            </tbody>
            </table>
          
          </div>
        </Drawer>
        
      <div id="map" style={{ width: "100%", height: "600px", padding: '10px 10px 50px 10px' }} ></div>
      
      {/* Content of the menu  */}
      <div id="menu" className="d-none">
        <h3 className="text-center" style={{'backgroundColor': 'cornflowerblue'}}>Legende</h3>
        <div className="panel-body">
          <h5>Einfach</h5>
          <table className="table">
              <tbody><tr>
                  <td>
                    <img alt="" src="/assets/images/atlas/atlas-1.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-21.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-22.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-23.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-13.svg"/>
                  </td>
                  <td>Vor gewähltem Stichjahr</td>
              </tr>
              <tr>
                  <td>
                    <img alt="" src="/assets/images/atlas/atlas-101.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-121.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-122.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-123.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-113.svg"/>
                  </td>
                  <td>Nach gewähltem Stichjahr</td>
              </tr>
              <tr>
                  <td>
                    <img alt="" src="/assets/images/atlas/atlas-201.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-221.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-222.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-223.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-213.svg"/>
                  </td>
                  <td>Vor und nach Stichjahr</td>
              </tr>
          </tbody>
          </table>
          <h5>Erweitert</h5>
          <table className="table">
              <tbody><tr>
                  <th colSpan="2">Farbe der Symbole</th>
              </tr>
              <tr>
                  <td className="text-center">
                    <img alt="" src="/assets/images/atlas/atlas-1.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-101.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-201.svg"/>
                  </td>
                  <td valign="middle">Einheimisch</td>
              </tr>
              <tr>
                  <td className="text-center">
                    <img alt="" src="/assets/images/atlas/atlas-21.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-121.svg"/>
                    <img alt="" src="/assets/images/atlas/atlas-221.svg"/>
                  </td>
                  <td valign="middle">Regional eingeführt</td>
              </tr>
              <tr>
                <td className="text-center">
                  <img alt="" src="/assets/images/atlas/atlas-22.svg"/>
                  <img alt="" src="/assets/images/atlas/atlas-122.svg"/>
                  <img alt="" src="/assets/images/atlas/atlas-222.svg"/>
                </td>
                <td valign="middle">Eingeführt</td>
              </tr>
              <tr>
                <td className="text-center">
                  <img alt="" src="/assets/images/atlas/atlas-23.svg"/>
                  <img alt="" src="/assets/images/atlas/atlas-123.svg"/>
                  <img alt="" src="/assets/images/atlas/atlas-223.svg"/>
                </td>
                <td valign="middle">Invasiver Neophyt</td>
              </tr>
              <tr>
                <td className="text-center">
                  <img alt="" src="/assets/images/atlas/atlas-13.svg"/>
                  <img alt="" src="/assets/images/atlas/atlas-113.svg"/>
                  <img alt="" src="/assets/images/atlas/atlas-213.svg"/>
                </td>
                <td valign="middle">Wiederangesiedelt, einheimisch</td>
              </tr>
          </tbody>
          </table>
      
        </div>
        
        <div className="data"></div>
      </div>
      
      </>
      );
  }
}

function mapStateToProps(state) {
  //console.log("mapStateToProps",state);
  return {
    taxonDefaultYear: state.taxonDefaultYear
    
  };
}

export default withRouter(connect(mapStateToProps, { getActuelYear } )((FlibMap)));
