import React from 'react';
import InputBase from '@material-ui/core/InputBase';
import { fade, withStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from "react-redux";
import { getData, getTaxon } from "../api/actions/index";
import { withRouter } from 'react-router-dom';
import {Popper, Fade, ClickAwayListener, Grow, Paper } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '35ch',
      },
    },
  },
  inputRootLeftSide: {
    color: 'inherit',
    '&:focus': {
      borderColor: theme.palette.success.light,
    },
  },
  inputInputLeftSide: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    width: '24ch',
    borderColor: theme.palette.success.light,
    '&:focus': {
      borderColor: theme.palette.success.light,
    },
  },
  searchLeftSide: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.45),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.95),
    },
    marginLeft: 0,
    width: '100%',
  },
});


class SearchAppBar extends React.Component{

    constructor(props) {
        super(props)
    
        this.state = {
            baseInputSelectedValue : '',
            selectedIndex: null,
            open: false,
            anchorEl: null,
            data:[],
            taxonRemoteData:[],
            list: undefined,
            isNeueID: false
        }
    }

    componentDidMount() {
      //console.log('Auto Pros', this.props);
       this.props.getData();
    }

    componentDidUpdate(prevProps) {

      //console.log("componentDidUpdate",this.props,prevProps);

      /* if (this.props !== prevProps) {
        if(this.props.articles!== undefined){
        
          this.setState({
            data: this.props.articles.listTaxon,
            taxonRemoteData: this.props.taxonRemoteData
          });
        }
      } */
    }


    render () {
        // className={classes.search}

        
        const searchData = (e) => {
            var queryData = [];
            // eslint-disable-next-line
            if(e.target.value != '' && this.props.articles !== undefined && this.props.articles.listTaxon !== undefined) {
                this.props.articles.listTaxon.forEach((taxon) => {
                  
                  switch (this.props.searchType) {
                    case 'all':
                      // eslint-disable-next-line
                      if(taxon.latin.toLowerCase().indexOf(e.target.value.toLowerCase())!=-1 || taxon.german.toLowerCase().indexOf(e.target.value.toLowerCase())!=-1) {
                        if(queryData.length < 150) {
                          queryData.push(taxon);
                        }
                      }
                      break;
                    case 'latin':
                      // eslint-disable-next-line
                      if(taxon.latin.toLowerCase().indexOf(e.target.value.toLowerCase())!=-1) {
                        if(queryData.length < 150) {
                          queryData.push(taxon);
                        }
                      }
                      break;
                    case 'german':
                      // eslint-disable-next-line
                      if(taxon.german.toLowerCase().indexOf(e.target.value.toLowerCase())!=-1) {
                        if(queryData.length < 150) {
                          queryData.push(taxon);
                        }
                      }
                      break;
                  
                    default:
                      break;
                  }

              });
            }
            this.setState({
              list: queryData,
              baseInputSelectedValue: e.target.value
            });
        }

        const {classes} = this.props;

        const id = this.state.open ? 'faked-reference-popper' : undefined;

      const handleClick = (event,index) => {
        
        this.setState(
          {
            anchorEl: event.currentTarget,
            open: true
          }
        )
      }

      const handleItemClick = (event) =>{
        
        const { taxonLink, taxonId } = event.currentTarget.dataset;

        this.props.getTaxon(taxonId);
        this.setState({isNeueID:true})

        handleClose(event)

        this.props.history.push(taxonLink,{data: this.props.articles});

      }

      const handleClose = (event) => {
        if (this.state.anchorEl && this.state.anchorEl.contains(event.target)) {
          return;
        }
    
        this.setState({
            open:false,
            baseInputSelectedValue:''
          });
      }

      ////console.log(this.props.articles);
        return (

            <div className={this.props.searchType === 'all' ? classes.search : classes.searchLeftSide}>
                <div className={classes.searchIcon}>
                    <SearchIcon />
                </div>
                <InputBase
                    value={this.state.baseInputSelectedValue}
                    onClick={handleClick} 
                    onChange={searchData}
                    placeholder={this.props.searchdata.placeholder}
                    classes={{
                        root: (this.props.searchType === 'all' ? classes.inputRoot : classes.inputRootLeftSide),
                        input: (this.props.searchType === 'all' ? classes.inputInput :  classes.inputInputLeftSide),
                    }}
                    inputProps={{ 'aria-label': 'search' }}
                />
                <Popper style={{zIndex: 1800}} id={id} open={this.state.open} anchorEl={this.state.anchorEl} transition placement="bottom-start">
                    {({ TransitionProps, placement }) => (
                      <Fade {...TransitionProps} timeout={350}>
                      <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                          >
                          <Paper style={{maxHeight:'300px', overflowY:'auto', width:'content'}}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <List dense={true} id="split-button-menu">
                                {(this.props.articles !== undefined) && (this.state.list !== undefined) && this.state.list.map((option, index) => (
                                  <ListItem
                                    key={index}
                                    //disabled={index === 2}
                                    //selected={index === selectedIndex}
                                    //onClick={(event) => handleMenuItemClick(event, index)}
                                    selected={this.state.selectedIndex === index}
                                    onClick={handleItemClick} 
                                    data-taxon-id = { option.id}
                                    data-taxon-link={"/flib/flora/" + option.id + "/" + option.latin.replace(/\s/g, "-")}
                                  >
                                    <ListItemText primary={this.props.searchType === 'german' ? option.german : option.latin} secondary={this.props.searchType === 'german' ? option.latin : option.german} />

                                  </ListItem>
                                ))}
                              </List>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      </Fade>
                    )}
                </Popper>
            </div>
               
        )
    }
}

function mapStateToProps(state) {
    //console.log("mapStateToProps SearchAuto",state);
    return {
      articles: state.remoteArticles[0],
      taxonRemoteData: state.remoteTaxon[0],
      taxonDefaultYear: state.taxonDefaultYear
    };
  }
  

export default withRouter(connect(mapStateToProps, { getData, getTaxon } )((withStyles(useStyles)(SearchAppBar))));
