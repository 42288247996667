import { ADD_ARTICLE } from "../constants/action-types";
import '../../configs/config'
import Axios from 'axios'

export function addArticle(payload) {
  return { type: ADD_ARTICLE, payload };
}

/* export function getObservation() {
   return { type: "GET_ACTUELTAXONOBS" };
} */

export function getData() {
    return function(dispatch) {
     Axios.post(global.config.TaxonUrl, {query: `
                    query{
                    listTaxon{
                        id
                        familyID
                        familyName
                        genusID
                        genusName
                        latin
                        german
                        nbrtaxaobs
                    }
                    ListGenus{
                        id
                        familyID
                        genusName
                    }
                    ListFamily{
                        id
                        familyName
                    }
                    }`},{
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                      }
                  })
                    .then(json => {
                        ////console.log("FlibStore", json.data.data);
                        return dispatch({ type: "DATA_LOADED", payload: json.data.data });
                    })
      };
  }
  export function getTaxon(taxonID) {
    return function(dispatch) {
     Axios.post(global.config.TaxonUrl, {query: `
                    query{
                        TaxonAttributsInfos(taxAttributID:${taxonID}) {
                            type
                            crs {
                              type
                              properties {
                                name
                              }
                              TaxonInfos {
                                minYear
                                maxYear
                                totalObs
                                sisf_id
                                latin
                                german
                                family {
                                  id
                                  familyName
                                }
                                genus {
                                  id
                                  genusName
                                }
                                redlist
                                redlist_ch
                                reg_protection
                                ch_protection
                                priority
                                responsability
                                rang
                              }
                            }
                          }
                          taxonFeatures(taxonID:${taxonID}){
                            type
                            geometry {
                              type 
                              coordinates
                            }
                            properties {
                              copyright
                                    doubt
                                    intro
                                    obs_nb
                                    obs_year
                                    xy_precision
                                    validation_status
                                    introduction_status
                                    v_observers
                            }
                          }
                    }`},{
                      headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                      }
                    })
                    .then(json => {
                        ////console.log("FlibStore", json.data.data);
                        return dispatch({ type: "TAXON_LOADED", payload: json.data.data });
                    })
      };
  }


  export function getActuelYear(year) {
    return { type: "SET_ACTUELYEAR", payload: { year } };
  }
 /*
    fetch({
            url: global.config.TaxonUrl,
            query: `
                    query{
                    listTaxon{
                        id
                        genusID
                        familyID
                        latin
                        german
                        nbrtaxaobs
                    }
                    ListGenus{
                        id
                        familyID
                        genusName
                    }
                    ListFamily{
                        id
                        familyName
                    }
                    }`
        })
          .then(response => response.json())
          .then(json => {
            ////console.log("FlibStore", json);
            dispatch({ type: "DATA_LOADED", payload: json });
          });
  } */