import React from 'react'

export default function IndexContent() {
    return (
        <div className="colonne recherche">
            <h2>Florenatlas der Stadt Bern</h2>
            <div className="col-md-12">
                <h2>Das Projekt</h2>
                <br />
                <p className="text-justify">Siedlungsräume sind reich an wildwachsenden Pflanzenarten, wie es Stadtflorakartierungen in Lausanne, Genf, Zürich und Freiburg bereits gezeigt haben.</p>
                <p className="text-justify"><strong>Wie steht es wohl um die stadtberner Pflanzenvielfalt?</strong> Um dies herauszufinden, haben Stadtgrün Bern und die Bernische Botanische Gesellschaft 2012 gemeinsam die Trägerschaft für eine Erhebung der Wildpflanzen übernommen – das Floreninventar Bern FLIB. Kartiermethode und Artenliste konnten von der Kartierung «Flora des Kantons Zürich FLOZ» übernommen und an Berner Verhältnisse angepasst werden. Seit Frühling 2013 sind nun über 100 Pflanzenkennerinnen und - kenner mit grossem Engagement daran, die Flora der Gemeinde Bern ehrenamtlich zu erfassen. Die Kartierung durch Ehrenamtliche&nbsp;wurde Ende 2015 abgeschlossen.&nbsp;Zur Zeit (2016) laufen die gezielten Nachkontrollen und Nachkartierungen durch SpezialistInnen. Bis heute wurden gegen 60‘000 Fundmeldungen in die Datenbank von Info Flora eingegeben. <strong>Die Kenntnis der Berner Flora hat sich dabei auf 1627 Pflanzenarten erweitert</strong>. Besonders bemerkenswert ist, dass sich darunter 142 schweizweit gefährdete Arten befinden, sechs davon sind sogar vom Aussterben bedroht.</p>
                <p>Mehr zur FLIB-Kartiermethode auf der Seite ....</p>
                <br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />
            </div>
        </div>
    )
}
