import React from 'react'
import { Route, Switch } from 'react-router-dom';
import IndexContent from './pages/IndexContent';
import BaumartenCnt from './pages/BaumartenCnt';
import ArtmeldenCnt from './pages/ArtmeldenCnt';
import SiedlungartenCnt from './pages/SiedlungartenCnt';
import SpezifischevielfaltCnt from './pages/SpezifischevielfaltCnt';
import WaldartenCnt from './pages/WaldartenCnt';
import Gefaehrdete from './pages/GefaehrdeteCnt'
import SearchTblCnt from './pages/SearchTblCntNew';
import ContactCnt from './pages/ContactCnt';
import FlibProjektCnt from './pages/FlibProjektCnt';
import SimpleCard from './pages/SimpleCard';
import ListObject from './pages/ListObject';


function Routes() {
    return (
        <Switch>
            <Route exact path='.' component={IndexContent} />
            <Route exact path='/' component={IndexContent} />
            <Route exact path='/Baumarten' component={BaumartenCnt} />
            <Route exact path='/Artmelden' component={ArtmeldenCnt} />
            <Route exact path='/Siedlungarten' component={SiedlungartenCnt} />
            <Route exact path='/Spezifischevielfalt' component={SpezifischevielfaltCnt} />
            <Route exact path='/Waldarten' component={WaldartenCnt} />
            <Route exact path='/Gefaehrdeten' component={Gefaehrdete} />
            <Route exact path='/Search' component={SearchTblCnt} />
            <Route exact path='/Kontakt' component={ContactCnt} />
            <Route exact path='/FlibProjekt' component={FlibProjektCnt} />
            <Route exact path='/flib/flora/:taxonID/:taxonName' component={SimpleCard} />
            <Route exact path='/flib/flora/familie/:famID/:famileName' component={ListObject} />
            <Route exact path='/flib/flora/gattungen/:genID/:genName' component={ListObject} />
        </Switch>
    )
}

export default Routes
