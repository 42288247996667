import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import berngrunlogo from './assets/img/berngrun-logo.png';
import bbglogo from './assets/img/bbg-logo.png';
import navfoto1 from './assets/img/navfoto1.png';
import navfoto2 from './assets/img/navfoto2.png';
import home from './assets/img/home.png';
import './App.css';
import SearchContent from './pages/SearchContent';
import SearchAutoComplete from './pages/SearchAutoComplete'

import Routes from './Routes';
import store from "./api/store/index";


class App extends React.Component {

  /* constructor(props) {
    super(props);
  } */

  authenticate(){
    return new Promise(resolve => setTimeout(resolve, 2000)) // 2 seconds
  }

  componentDidMount(){
    this.authenticate().then(() => {
      const ele = document.getElementById('ipl-progress-indicator')
      if(ele){
        // fade out
        ele.classList.add('available')
        setTimeout(() => {
          // remove from DOM
          ele.outerHTML = ''
        }, 2000)
      }
    })
  }


  render () {

    ////console.log('Store',store);
    

  return (

    <Router store={store}>
    <div className="container">
      <div className="relative d-none d-lg-block d-xl-block"><h3>ATLAS FLORA DER STADT BERN</h3>
        <div className="absolute"><img src={berngrunlogo} alt="Logo"/></div>
        <div className="logobbg"><img  src={bbglogo} alt="Logo" /></div>
        <div className="foto1"><img src={navfoto1} alt="" /></div>
        <div className="foto2"><img src={navfoto2} alt="" /></div>
      </div>
      
      <Navbar bg="light" expand="lg" sticky="top" className="bg-info">
          <LinkContainer to="/"><Navbar.Brand href="/"><img src={home} width="35" height="35" alt="Home" /></Navbar.Brand></LinkContainer>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <NavDropdown title="Atlas" id="atlas-nav-dropdown">
              <LinkContainer to="/Search"><NavDropdown.Item>Art abfragen</NavDropdown.Item></LinkContainer>
              <NavDropdown.Divider />
              <LinkContainer to = "/Spezifischevielfalt" ><NavDropdown.Item>Spezifische vielfalt</NavDropdown.Item></LinkContainer>
              <LinkContainer to="/Gefaehrdeten"><NavDropdown.Item>Verteilung gefährdeten Arten</NavDropdown.Item></LinkContainer>
              <LinkContainer to="/Waldarten"><NavDropdown.Item>Verteilung Waldarten</NavDropdown.Item></LinkContainer>
              <LinkContainer to="/Siedlungarten"><NavDropdown.Item>Verteilung Siedlungstypischen Arten</NavDropdown.Item></LinkContainer>
              <LinkContainer to="/Baumarten"><NavDropdown.Item>Verteilung Baumarten</NavDropdown.Item></LinkContainer>
            </NavDropdown>
            <NavDropdown title="Flib" id="floreninventar-nav-dropdown">
              <LinkContainer to="/FlibProjekt"><NavDropdown.Item>Das Projekt</NavDropdown.Item></LinkContainer>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">Artenliste pdf</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">Kartieranleitung pdf</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#">Melderinnen und Melder</NavDropdown.Item>
            </NavDropdown>
            <LinkContainer to="/Artmelden"><Nav.Link className="nav-item">Arten melden</Nav.Link></LinkContainer>
            <LinkContainer to="/Kontakt"><Nav.Link className="nav-item">Kontakt</Nav.Link></LinkContainer>
            <NavDropdown title="Links" id="basic-nav-dropdown">
              <NavDropdown.Item href="#">Link 1</NavDropdown.Item>
              <NavDropdown.Item href="#">Link 2</NavDropdown.Item>
              <NavDropdown.Item href="#">Link 3</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">Link 4</NavDropdown.Item>
            </NavDropdown>
          </Nav>
            {/* <AutoComplete /> {/*  <FormControl type="text" placeholder="Art Suchen" className="mr-sm-5 input-search" /> */}
            <div style={{marginRight: 35}}><SearchAutoComplete searchdata = {{placeholder:'Art Suchen', filterSrc: 'latin'}} searchType="all" /></div>
            {/* <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Search…"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              inputProps={{ 'aria-label': 'search' }}
            />
          </div> */}
        </Navbar.Collapse>
      </Navbar>
      
      <div className="container-fluid">

        <div className="row">
          <div className="col-sm-12">
            <div className="row">
              <div className="col-sm-3">
              
                <SearchContent />
              
              </div>
              <div className="col-sm-9">
                
                  <Routes />
              
                  </div>
            </div>
          </div>
        </div>

      </div>

    </div>
    </Router>
    

  );
}
}


export default App;
