import React from 'react';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import { withRouter } from 'react-router-dom';
import SplitButton from './SplitButton';
import { connect } from "react-redux";
import { getData } from "../api/actions/index";
import LinearProgress from '@material-ui/core/LinearProgress';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';


function groupBy (tableauObjets, propriete) {
    return tableauObjets.reduce(function (acc, obj) {
      var cle = obj[propriete];
      if(!acc[cle]){
        acc[cle] = [];
      }
      acc[cle].push(obj);
      return acc;
    }, {});
}

let myList, myOptions, familyList = "";

class IconLabelButtons extends React.Component {
  
    constructor(props) {
        super(props)
        this.state = {
            filterBy : 'target',
            objTarget: 'genus', // Incomming Family or Genus
            objTargetID: 0,
            selVal: {
                    id:0,
                    familyName:''
                    }
        }
        
    }

    componentDidMount() {
        this.props.getData();
        ////console.log(this.props);
        this.setState({
            filterBy : this.props.location.state !== undefined ? this.props.location.state.filterBy :'target',
            objTarget: this.props.targetInfo !== undefined ? this.props.targetInfo.objTarget : 'genus', // Incomming Family or Genus
            objTargetID: this.props.match.params.famID,
            selVal: {
                    id: this.props.match.params.famID !== undefined ? parseInt(this.props.match.params.famID) : 0,
                    familyName: this.props.match.params.famileName !== undefined ? this.props.match.params.famileName : ""
                    }
        })
    }

    componentDidUpdate(prevProps) {
        
        if (this.props !== prevProps) {
            //console.log("componentDidUpdate OK",this.props,prevProps);
            this.setState({
                filterBy: this.props.location.state !== undefined ? (this.props.location.state.filterBy !== undefined ?  this.props.location.state.filterBy : this.props.match.params.genName) :'target',
                selVal: {
                    id: parseInt(this.props.match.params.famID) > 0 ? parseInt(this.props.match.params.famID) : 0 ,
                    familyName:(parseInt(this.props.match.params.famID) > 0 ) ? this.props.match.params.famileName : ""
                    }
            });
        }
    }
    
    render () {

        const onAlphabetClick = (e) => {
            e.preventDefault();
            ////console.log({alphabet: e.target.value})
            this.setState({
                filterBy: e.target.value,
                selVal: {
                    id: 0,
                    familyName: ""
                    }
            })
        }
        
        const prepareAlphabets = () => {
            let result = [];
            for(let i=65; i<91; i++) {
              result.push(
                    <input onClick={onAlphabetClick} className="btn-outline-success btn-sm"  key={i} name="bt_nom_latin" type="submit" value={String.fromCharCode(i)} />
                )
            }
            return result
        }  
        
        
        if( this.props.articles !== undefined){
            myList= groupBy(this.props.articles.listTaxon, "genusID");
            familyList = this.props.articles.ListFamily;
            ////console.log(this.state.objTargetID,familyList[this.state.objTargetID-1]);
            
            if(this.state.filterBy === 'target' || this.state.filterBy === undefined){
                // eslint-disable-next-line
                myOptions = Object.values(myList).filter(o => o[0].familyID == this.state.objTargetID)
            }else if( this.state.filterBy !== undefined && this.state.filterBy !== 'target' && this.state.filterBy.length === 1 ) {
                myOptions = Object.values(myList).filter(o => o[0].genusName.charAt(0).toLocaleLowerCase() === this.state.filterBy.toLocaleLowerCase())
            }else if( this.state.filterBy !== undefined && this.state.filterBy !== 'target' && this.state.filterBy.length > 1 ) {
                //console.log("filterBy", this.state.filterBy);
                myOptions = Object.values(myList).filter(o => o[0].genusName.toLocaleLowerCase() === this.state.filterBy.toLocaleLowerCase())
            
            }else{
                myOptions = []
            }
        }

        return (
            
            <Card className="mt-2 p-4">
                <div className="row">
                    <div className="col-6">
                        <h4>Liste Familie</h4>
                        <hr />
                        {
                        (this.props.articles !== undefined && familyList.length > 0) && 
                        <Autocomplete
                            id="flib-taxon-family-list"
                            noOptionsText="Keine Art Familie vorhanden"
                            style={{ width: '100%' }}
                            options={familyList}
                            autoHighlight
                            //defaultValue={familyList[this.state.objTargetID-1]}
                            onChange={(event, newValue) => {
                                ////console.log(event,newValue);
                                if(newValue!== null)
                                this.setState({
                                    filterBy: 'target',
                                    objTargetID: newValue.id,
                                    selVal: {
                                        id          : newValue.id,
                                        familyName  : newValue.familyName
                                    }
                                });
                            }}
                            value={this.state.selVal}
                            getOptionLabel={(option) => (option.familyName !== undefined) ? option.familyName: ""}
                            renderOption={(option) => (
                            <React.Fragment>
                                {option.familyName}
                            </React.Fragment>
                            )}
                            getOptionSelected={(option, value) => {
                                return option.id === value.id;
                             }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Wählen Sie die Familie Art"
                                variant="outlined"
                                inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                          />
                        )}
                      />
                    }
                        <hr />
                    </div>
                    <div className="col-6">
                        <h4>Gattungen Gruppe</h4>
                        <hr />
                            {prepareAlphabets()}
                        <hr />
                    </div>
                </div>
                
                { 
                    (() => {
                        switch(this.props.articles) {
                            case undefined:
                                return <LinearProgress />
                            default :
                                return (
                                   <>
                                   <div className="row center"><h5>Liste der Gattungen</h5></div>
                                    <Grid container direction="row" alignItems="center">
                                    
                                            {/* This Button uses a Font Icon, see the installation instructions in the Icon component docs. */}
                                            {
                                                    myOptions.map((obj, index) => (
                                                        <span key={index}>
                                                            <SplitButton key={"lst_" + index} menudata={obj}/>
                                                        </span>
                                                    )
                                                )
                                            }
                                        
                                    </Grid>
                                    </>
                                )
                        }
                    })()
                } 
                    
            </Card>
                    
        );


    }
    
}

function mapStateToProps(state) {
    ////console.log("mapStateToProps",state);
    return {
      articles: state.remoteArticles[0]
    };
  }
  
export default withRouter(connect(
    mapStateToProps,
    { getData }
  )(IconLabelButtons));