import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import TaxonInfos from './TaxonInfos'
import Breadcrumbs from './Breadcrumbs';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from "react-redux";
import { getData, getTaxon } from "../api/actions/index";
import { withRouter } from 'react-router-dom';


/* const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(16),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
 */
  const useStylesBootstrap = makeStyles((theme) => ({
    arrow: {
      color: theme.palette.common.black,
    },
    tooltip: {
      backgroundColor: theme.palette.common.black,
      fontSize: theme.typography.pxToRem(16),
    },
  }));

  function BootstrapTooltip(props) {
    const classes = useStylesBootstrap();
  
    return <Tooltip arrow classes={classes} {...props} />;
  }

let taxonParGenus, genusParFamily =null;

function groupBy (tableauObjets, propriete) {
    return tableauObjets.reduce(function (acc, obj) {
      var cle = obj[propriete];
      if(!acc[cle]){
        acc[cle] = [];
      }
      acc[cle].push(obj);
      return acc;
    }, {});
}

 
let nexttaxonLink, prevtaxonLink, myPivot, textGerman, previd, nextid;  

class SimpleCard extends React.Component {
    
    constructor(props) {
        super(props)
        // eslint-disable-next-line    
        const elmAct = this.props.articles !== undefined ? this.props.articles.listTaxon.find(obj => { return obj.id == this.props.match.params.taxonID }): []

        this.state = {
          key:null,
          allData: this.props.articles !== undefined ? this.props.articles : [],
          taxonRemoteData: this.props.taxonRemoteData !== undefined ? this.props.taxonRemoteData : [],
          dataActuel: elmAct,
          taxonParGenus: this.props.articles !== undefined ?  groupBy(this.props.articles.listTaxon, "genusID")[[elmAct.genusID]] :null,
          genusParFamily: this.props.articles !== undefined ? groupBy(this.props.articles.ListGenus, "familyID")[elmAct.familyID] :null
        }
    }

    traitData(){
      if(this.props.taxonRemoteData !== undefined) {
        this.setState({
          taxonRemoteData: this.props.taxonRemoteData
        })
      }


      if(this.props.articles !== undefined) {
          taxonParGenus =  groupBy(this.props.articles.listTaxon, "genusID");
          genusParFamily =  groupBy(this.props.articles.ListGenus, "familyID");
          // eslint-disable-next-line
          const elmAct = this.props.articles.listTaxon.find(obj => { return obj.id == this.props.match.params.taxonID })
          this.setState({

              allData         : this.props.articles,
              dataActuel      : elmAct,
              taxonParGenus   : taxonParGenus[elmAct.genusID],
              genusParFamily  : genusParFamily[elmAct.familyID]

          })
      }

       if (this.state.taxonParGenus !== null) {
         myPivot = 0
         this.state.taxonParGenus.find((obj, index) => {
           // eslint-disable-next-line
           if (obj.id == this.props.match.params.taxonID) {
             myPivot = index
             return index
           }else{ return null}
         })

         if ((myPivot - 1) >= 0) {
           previd = this.state.taxonParGenus[myPivot - 1].id
           prevtaxonLink = '/flib/flora/' + previd + "/" + this.state.taxonParGenus[myPivot - 1].latin.replace(/\s/g, "-")
           /* this.setState({
             prevBtn: ""
           }) */
           this.previousBtn.disabled = false
         } else {
           //btn prev disabled
           //btnprev = 'disabled'
           /* this.setState({
             prevBtn: "true"
           }) */
           this.previousBtn.disabled = true
         }
         if ((myPivot + 1) < this.state.taxonParGenus.length) {
           nextid = this.state.taxonParGenus[myPivot + 1].id
           nexttaxonLink = '/flib/flora/' + nextid + "/" + this.state.taxonParGenus[myPivot + 1].latin.replace(/\s/g, "-")
           //btnnext = 'false'
           this.nextBtn.disabled = false
           /* this.setState({
             nextBtn: ""
           }) */
         } else {
           //btn next disabled
           //btnnext = 'true'
            this.nextBtn.disabled = true
           /* this.setState({
             nextBtn: "true"
           }) */
         }

         ////console.log(nexttaxonLink,prevtaxonLink);

       }


    }

    componentDidMount() {

        //console.log("componentDidMount********************************");
        /* this.setState({
          key: Math.random()
        }) */
        //console.log(this.props);
        if(this.props.articles === undefined){
          //console.log("No Articles");
          this.props.getData();
          this.traitData();
        }
       

    }

    componentDidUpdate(prevProps) {
        // Utilisation classique (pensez bien à comparer les props) :
        //console.log("componentDidUpdate",this.props,prevProps);
        if (this.props !== prevProps) {
          //this.fetchData(this.props.userID);
          //console.log("componentDidUpdate Props",this.props);
          //console.log("componentDidUpdate prevProps",prevProps);
         
          if(this.props.taxonRemoteData === undefined) {
            //console.log('this.state.taxonRemoteData.length = 0', Object.keys(this.state.taxonRemoteData).length);
            this.props.getTaxon(this.props.match.params.taxonID);
          }else if (this.props.taxonRemoteData !== undefined && Object.keys(this.props.taxonRemoteData).length>0 && this.props.match.params.taxonID !== prevProps.match.params.taxonID){
            //console.log('this.state.taxonRemoteData.length > 0', Object.keys(this.state.taxonRemoteData).length);
            this.props.getTaxon(this.props.match.params.taxonID);
          }
          this.traitData()
        }
      }

    goToLink(myLink, actID){

        if(myLink !== undefined){
            this.setState({
              key: Math.random()
            })
            
            this.props.history.push(myLink,{ data: this.props.articles });
           
        }
        
    }

    
    render (){ 

        /* if(this.state.taxonRemoteData.TaxonAttributsInfos !== undefined && Object.keys(this.props.taxonRemoteData.TaxonAttributsInfos).length>0){ 
          if( parseInt(this.props.match.params.taxonID) !== this.props.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.sisf_id){
            //console.log("componentDidUpdate new ID", this.props);
            this.setState({
              taxonRemoteData:[]
            })
            //this.props.getTaxon(parseInt(this.props.match.params.taxonID));
          }
        } */

        ////console.log("Render",this.props);
        ////console.log('DataAct German', this.state.dataActuel.german);
        
        textGerman = "";

        if (this.state.dataActuel.german !== undefined && this.state.dataActuel.german !== "" ) {
            ////console.log("Germa text", this.state.dataActuel.german);
            textGerman = <BootstrapTooltip  title={this.state.dataActuel.german}><h4 style={{maxWidth: '350px'}} className="float-md-right mr-2 mt-2 text-truncate"> {this.state.dataActuel.german}</h4></BootstrapTooltip>

        }


        return ( (this.props.articles !== undefined) && 
            <Card className="mt-2">
            <CardContent>
            
                <div className="taxonBage" color="textSecondary">
                        <Badge color="default" max={9999999} >
                            <BootstrapTooltip  title={this.state.dataActuel.latin !== undefined ? this.state.dataActuel.latin : "FlibInfos"}>
                                <h3  style={{maxWidth: '450px'}} className="text-truncate">{this.state.dataActuel.latin}</h3>
                            </BootstrapTooltip>
                        </Badge>
                        
                        {textGerman}
                        
                </div>
                <Typography variant="h6" component="h6">       
                    <div className="col-md-12 col-sm-12 m-4">
                        <div className="btn-group float-md-right btn-group-toggle mr-2 mb-3" data-toggle="buttons" style={{zIndex:'900'}}>
                            <button type="button" className="btn-success btn-sm" onClick={ () => {this.goToLink("/Search")} }>
                                <i className="fas fa-search"></i> Suche
                            </button>
                            <button ref={(previousBtn) => this.previousBtn = previousBtn} type="button" className={'btn-success btn-sm'} onClick={() => {this.goToLink(prevtaxonLink, previd)}} >
                                <i className="fas fa-arrow-left"></i> Vorherige
                            </button>
                            <button ref={(nextBtn) => this.nextBtn=nextBtn} type="button" className={'btn-success btn-sm'} onClick={() => {this.goToLink(nexttaxonLink,nextid)} }>
                                Nächste <i className="fas fa-arrow-right"></i>
                            </button>
                        </div>
                    </div>
                </Typography>
                <div color="textSecondary">
                    <Breadcrumbs taxonData={this.state}/>
                </div>
                <div variant="body2" component="p">
                   {this.state.taxonRemoteData.TaxonAttributsInfos !== undefined && <TaxonInfos taxonData={this.state} key={this.state.key}/>}
                </div>
            </CardContent>
          
            </Card>
        );
    }
}

function mapStateToProps(state) {
    ////console.log("mapStateToProps",state);
    return {
      articles: state.remoteArticles[0],
      taxonRemoteData: state.remoteTaxon[0]
      
    };
  }
  

export default withRouter(connect(mapStateToProps, { getData, getTaxon } )(SimpleCard));