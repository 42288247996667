import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Badge from '@material-ui/core/Badge';
import Chip from '@material-ui/core/Chip';
import ListIcon from '@material-ui/icons/List';
import ListAltIcon from '@material-ui/icons/ListAlt';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { withRouter } from 'react-router-dom';
//import { connect } from "react-redux";
//import { getTaxon } from "../api/actions/index";
import {Popper, Fade, MenuItem, MenuList, ClickAwayListener, Grow, Paper } from '@material-ui/core';

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591





class  CustomizedBreadcrumbs extends React.Component {

    constructor(props) {
        super(props)
        
        this.state = {
             open: false,
             anchorEl: null,
             labels : {
                family: this.props.taxonData.dataActuel.familyName,
                genus: this.props.taxonData.dataActuel.genusName,
                taxon: this.props.taxonData.dataActuel.latin

             }
        }

    }

    componentDidMount() {

        this.setState({

            labels : {
                family: this.props.taxonData.familyName,
                genus: this.props.taxonData.genusName,
                taxon: this.props.taxonData.latin

            }
        })
    }

    componentDidUpdate(prevProps) {
      //this.props.getTaxon(this.props.match.params.taxonID);
    }


    render(){

      const id = this.state.open ? 'faked-reference-popper' : undefined;

      const handleClick = (event) => {
        
        //const { taxonLink } = event.currentTarget.dataset;
        
        //this.props.history.push(taxonLink);
        //console.info('You clicked a breadcrumb.');
        this.setState(
          {anchorEl: event.currentTarget,
          open: true}
        )
        //console.log(this.state)
        //event.preventDefault();
      }

      const handleItemClick = (event) =>{
        //console.log(event.nativeEvent.target.outerText);
        const { taxonLink } = event.currentTarget.dataset;
        
        handleClose(event)
      
        this.props.history.push(taxonLink);
      }

      const handleClose = (event) => {
        if (this.state.anchorEl && this.state.anchorEl.contains(event.target)) {
          return;
        }
    
        this.setState({open:false});
      }

        return ( 
            <Breadcrumbs className="taxonBage" separator="⤃" aria-label="breadcrumb">
            <Badge color="secondary" max={9999999} badgeContent={(this.props.taxonData.genusParFamily !== null)?this.props.taxonData.genusParFamily.length:0} anchorOrigin={{vertical: 'bottom', horizontal: 'right', }}>
              <StyledBreadcrumb
                  component="a"
                  href="#"
                  data-taxon-link={"/flib/flora/familie/" + this.props.taxonData.dataActuel.familyID + "/" + this.props.taxonData.dataActuel.familyName}
                  label={this.props.taxonData.dataActuel.familyName}
                  icon={<ListIcon fontSize="small" />}
                  onClick={handleItemClick}
              />
            </Badge>
            <Badge color="secondary" max={9999999} badgeContent={(this.props.taxonData.taxonParGenus !== null)?this.props.taxonData.taxonParGenus.length:0} anchorOrigin={{vertical: 'bottom', horizontal: 'right', }}>         
              <StyledBreadcrumb 
                  icon={<ListAltIcon fontSize="small" />}
                  deleteIcon={<ExpandMoreIcon />}
                  onDelete={handleClick}
                  component="a" 
                  href="#" 
                  label={this.props.taxonData.dataActuel.genusName} 
                  onClick={handleClick} />
                  <Popper id={id} open={this.state.open} anchorEl={this.state.anchorEl} transition placement="bottom-start">
                    {({ TransitionProps, placement }) => (
                      <Fade {...TransitionProps} timeout={350}>
                      <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                          >
                          <Paper style={{maxHeight:'290px', overflowY:'auto'}}>
                            <ClickAwayListener onClickAway={handleClose}>
                              <MenuList id="split-button-menu">
                                {this.props.taxonData.taxonParGenus.map((option, index) => (
                                  <MenuItem
                                    key={index}
                                    //disabled={index === 2}
                                    //selected={index === selectedIndex}
                                    //onClick={(event) => handleMenuItemClick(event, index)}
                                    onClick={handleItemClick} 
                                    data-taxon-link={"/flib/flora/" + option.id + "/" + option.latin.replace(/\s/g, "-")}
                                    data-taxon-id={option.id}
                                    >
                                    {option.latin}
                                  </MenuItem>
                                ))}
                              </MenuList>
                            </ClickAwayListener>
                          </Paper>
                        </Grow>
                      </Fade>
                    )}
                  </Popper>
            </Badge>
            <StyledBreadcrumb
            title={this.props.taxonData.dataActuel.latin}
                style={{maxWidth: '200px'}} 
                className="text-truncate"
                label={this.props.taxonData.dataActuel.latin}
                onClick={handleClick}
            />
            </Breadcrumbs>
        );

    }
}

/* function mapStateToProps(state) {
  ////console.log("mapStateToProps",state);
  return {
    articles: state.remoteArticles[0],
    taxonRemoteData: state.remoteTaxon[0]
    
  };
}
connect(mapStateToProps, { getTaxon } ) */


export default withRouter((CustomizedBreadcrumbs));
