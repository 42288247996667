import React from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Slider from '@material-ui/core/Slider';
import { green } from '@material-ui/core/colors';
import Box from '@material-ui/core/Box';
import FlibMap from './FlibMap';
import { withRouter } from 'react-router-dom';
import { connect } from "react-redux";
import { getActuelYear } from "../api/actions/index";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`action-tabpanel-${index}`}
      aria-labelledby={`action-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `action-tab-${index}`,
    'aria-controls': `action-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: '100%',
    position: 'relative',
    minHeight: 200,
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  fabGreen: {
    color: theme.palette.common.white,
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[600],
    },
  },
}));

function TaxonInfos(props){

    //console.log("TaxonInfos Props",props);
    
    const [minYear, setMinYear] = React.useState(Object.keys(props.taxonData.taxonRemoteData.TaxonAttributsInfos).length > 0 ? props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.minYear : 2000);
    const [maxYear, setMaxYear] = React.useState(Object.keys(props.taxonData.taxonRemoteData.TaxonAttributsInfos).length > 0 ? props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.maxYear : 2020);
    
    const [imgRegRedList] = React.useState(Object.keys(props.taxonData.taxonRemoteData.TaxonAttributsInfos).length > 0 ? (props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist !=="" ? props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist : 'trans') : 'trans');
    const [imgChRedList] = React.useState(Object.keys(props.taxonData.taxonRemoteData.TaxonAttributsInfos).length > 0 ? (props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist_ch !=="" ? props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist : 'trans')  : 'trans');

    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(0);
    const [taxonDefaultYear, setTaxonDefaultYear] = React.useState(parseInt((minYear+maxYear)/2))
    //const [taxonData, setTaxondata] = React.useState(props.taxonData)  
    const [key] = React.useState(Math.random())  

    if(Object.keys(props.taxonData.taxonRemoteData.TaxonAttributsInfos).length > 0 ){
      //const strLatin = props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.latin;
      //const strGerman = props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.german
      //console.log("Year UpD", minYear, props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.minYear );
      if(minYear !== props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.minYear){
        setMinYear(props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.minYear)
      }
      //console.log("Year UpD", maxYear, props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.maxYear );
      if(maxYear !== props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.maxYear){
        setMaxYear(props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.maxYear)
        props.getActuelYear(taxonDefaultYear);
      }

      if( imgRegRedList !== props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist){

        //setImgRegRedList(props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist !=="" ? props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist : 'trans');
      } 
     if(imgChRedList !== props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist_ch){

        //setImgChRedList(props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist_ch !=="" ? props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist_ch : 'trans' );
      }
      /* if(taxonDefaultYear !== parseInt((minYear+maxYear)/2) ){
        setTaxonDefaultYear(parseInt((minYear+maxYear)/2));
        //console.log('setTaxonDefaultYear updeted', taxonDefaultYear);
      }
 */
      
   }
    const handleChange = (event, newValue) => {
      setValue(newValue);
      //console.log(taxonData);
    };

    const handleChangeIndex = (index) => {
      setValue(index);
    };


  const changeYearThreshold = (event, newValue) =>{
    setTaxonDefaultYear(newValue);
    //setKey(Math.random());
    props.getActuelYear(newValue)
  }



  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
          aria-label="action tabs example"
        >
          <Tab label="Karte" {...a11yProps(0)} />
          <Tab label="Zusammenfassung" {...a11yProps(1)} />
          <Tab label="Status" {...a11yProps(2)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
         <FlibMap observation={props.taxonData} taxonYear={Object.keys(props.taxonDefaultYear).length > 0 ? props.taxonDefaultYear.year : taxonDefaultYear} key={key}/> 
          <div className="ml-4 mr-4">
            <Slider
              value={taxonDefaultYear}
              onChange={changeYearThreshold}
              //defaultValue={taxonDefaultYear}
              //getAriaValueText={valuetext}
              aria-labelledby="discrete-slider-small-steps"
              marks
              step={1}
              min={maxYear-minYear === 0 ? minYear-1 : minYear} //maxYear-minYear > 5 ? minYear-1 : minYear-3
              max={maxYear-minYear === 0 && maxYear+1 < 2020 ? maxYear+1 : maxYear} 
              valueLabelDisplay="on"
              className="ml-2"
              style={{width:'98%'}}
            />
            <Typography id="discrete-slider-small-steps" gutterBottom>
              Range von <strong>{minYear}</strong> bis <strong>{maxYear}</strong>
            </Typography>
          </div>      
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <div className="panel-body row">
          <div className="col-md-8">
            <table className="table">
              <tbody>
                <tr>
                    <td>Wissenschaftlicher Name</td>
                    <td><strong>{props.taxonData.dataActuel.latin}</strong></td>
                </tr>
                <tr>
                    <td>Deutscher Name</td>
                    <td><strong>{props.taxonData.dataActuel.german}</strong></td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-4">
            <table>
              <tbody>
                <tr>
                    <td><img alt="" src="/assets/images/card2.jpg"/></td>
                </tr>
              </tbody>
            </table>
          </div>
          </div>
        </TabPanel>
      
        <TabPanel value={value} index={2} dir={theme.direction}>
          <div className="row-fluid">
          <div id="taxRedLst" className="col-sm-12 col-md-12">
            <div className="row">
              <div className="col-sm-12 col-md-6">
                <div className="row">Schweiz</div><div style={{marginTop:"-3px", color:'rgb(204, 204, 153)'}} className="small">(Rote Liste national 2016)</div>
                <div className="row"><img id="taxChRedLst" alt="" title="" src={require('../assets/images/de/' + (Object.keys(props.taxonData.taxonRemoteData.TaxonAttributsInfos).length > 0 ? (props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist_ch !=="" ? props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist_ch : 'trans') : 'trans') + '.png')} /></div>
              </div>
              <div className="col-sm-12 col-md-6">
                <div className="row" align="center">Biogeografische Region Bern</div><div style={{marginTop:"-3px", color:'rgb(204, 204, 153)'}} className="small">(Rote Liste regional 2019)</div>
                <div className="row"><img id="taxBernRedLst" alt="" title="" src={require('../assets/images/de/' + (Object.keys(props.taxonData.taxonRemoteData.TaxonAttributsInfos).length > 0 ? (props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist !=="" ? props.taxonData.taxonRemoteData.TaxonAttributsInfos[0].crs.TaxonInfos.redlist : 'trans') : 'trans') + '.png')}/></div>
              </div>
              <table className="table table-condensed" style={{marginTop:"25px"}}>
                <tbody>
                    <tr>
                      <td><b>EX</b></td>
                      <td>weltweit ausgestorben (Extinct)</td>
                    </tr>
                    <tr>
                      <td><b>RE</b></td>
                      <td>regional beziehungsweise in der Schweiz ausgestorben (Regionally Extinct)</td>
                    </tr>
                    <tr>
                      <td><b>CR(PE)</b></td>
                      <td>verschollen, vermutlich in der Schweiz ausgestorben (Critically Endangered, Probably Extinct)</td>
                    </tr>
                    <tr>
                      <td><b>CR</b></td>
                      <td>vom Aussterben bedroht (Critically Endangered)</td>
                    </tr>
                    <tr>
                      <td><b>EN</b></td>
                      <td>stark gefährdet (Endangered)</td>
                    </tr>
                    <tr>
                      <td><b>VU</b></td>
                      <td>verletzlich (Vulnerable)</td>
                    </tr>
                    <tr>
                      <td><b>NT</b></td>
                      <td>potenziell gefährdet (Near Threatened)</td>
                    </tr>
                    <tr>
                      <td><b>LC</b></td>
                      <td>nicht gefährdet (Least Concern)</td>
                    </tr>
                  </tbody>
              </table>
            </div>
          </div>
        </div>
    
        </TabPanel>
        </SwipeableViews>
      {/* fabs.map((fab, index) => (
        <Zoom
          key={fab.color}
          in={value === index}
          timeout={transitionDuration}
          style={{
            transitionDelay: `${value === index ? transitionDuration.exit : 0}ms`,
          }}
          unmountOnExit
        >
        <Fab aria-label={fab.label} className={fab.className} color={fab.color}>
        {fab.icon}
      </Fab>
        </Zoom>
      )) */}
     
    </div>
  );
  
}

function mapStateToProps(state) {
  ////console.log("mapStateToProps SearchAuto",state);
  return {
    taxonDefaultYear: state.taxonDefaultYear
  };
}


export default withRouter(connect(mapStateToProps, { getActuelYear } )((TaxonInfos)));


//export default withRouter(TaxonInfos);
