import React from 'react'
import Organigramm from '../assets/img/organigramm.jpg'

function OrganisationCnt() {
    return (
        <div className="tbsContainer p-4">
            <div className="row">
                <hr className="visible-xs" />
                <div className="row">
                    <div className="col-md-4" style={{marginLeft: '12px'}}>
                    <h2>Kontakt</h2>
                    <br />
                    <h4>Koordination FLIB<br /><br />Nik Indermühle<br /><a href="mailto:flib@gmx">flib@gmx.ch</a></h4>
                    <h3>&nbsp;</h3>
                    </div>
                    <div className="col-lg-8" style={{marginLeft: '-12px'}}>
                        <h2>Organisation</h2>
                        <br />
                        <p><a data-fancybox data-caption="Organigramm" href={{Organigramm}} rel="organisation"> <img className="img img-rounded" style={{marginLeft: '-15px'}} src={Organigramm} alt="Organigramm" width="500px" /> </a></p>
                    </div>
                </div>
            </div>
		</div>
    )
}

export default OrganisationCnt
