import React from 'react';
import { withRouter } from 'react-router-dom';
import SearchAutoComplete from './SearchAutoComplete'
import { LinkContainer } from 'react-router-bootstrap'



function SearchContent(props) {

    const onAlphabetClick = (e) => {
        e.preventDefault();
        props.history.push("/flib/flora/familie/0/gattungen",{filterBy: e.target.value})
        //console.log({alphabet: e.target.value})
    }
    
    const prepareAlphabets = () => {
        let result = [];
        for(let i=65; i<91; i++) {
          result.push(
                <input onClick={onAlphabetClick} className="btn-success btn-sm"  key={i} name="bt_nom_latin" type="submit" value={String.fromCharCode(i)} />
            )
        }
        return result
    }    


    return (
        
        <div className="colonne sticky-top" style={{top:'80px', marginBottom:'30px'}}>
        <h2>Art abfragen 
			<span id="xsArten" className="btn btn-xs pull-right d-sm-none" style={{marginTop:'10px'}}>
				<i className="fas fa-angle-double-down"></i>
			</span>
		</h2>
        <div id="xspanel" className="d-none d-lg-block d-xl-block">
            
            <form action="#" method="post" target="_self">
                
                <div className="separation submit_fin">
                    <strong>Liste der Gattungen</strong>
                    <div className="lstletre hide">
                    {prepareAlphabets()}
                    </div>							
                    <hr />
                    <strong>Wissenschaftlicher Name</strong>
                    <div className="input-group">
                        {/* <input name='latin' type='search' id='autoLatin' className="form-control input-search loading" placeholder="Wissenschaftlicher Name" style=width:'100%' /> */}
                        <SearchAutoComplete className="form-control input-search loading" searchdata = {{placeholder:'Wissenschaftlicher Name', filterSrc: 'latin'}} searchType="latin" />
                    </div>

                    <hr />
                </div>
                <strong>Deutscher Name</strong>
                <div className="separation submit_fin">							
                    <div className="input-group">
                        <SearchAutoComplete variant="outlined" searchdata = {{placeholder:'Deutscher Name', filterSrc: 'latin'}} searchType="german" />
                    </div>
                    <hr />
                </div>
            </form>
            
            <div className="colonne recherche" style={{marginLeft:'-0.3em'}}>
                <h2>Auswertungen<span id="xsArten" className="btn btn-xs pull-right d-sm-none" style={{marginTop:'-5px'}}><i className="fas fa-angle-double-down"></i></span></h2>
                <div id="" className="xspanel d-none d-lg-block d-xl-block"></div>
                <ul className="list-group" style={{marginTop:'-1px', zIndex:"100"}}>
                    <LinkContainer to = "/Spezifischevielfalt" ><div className="list-group-item">Spezifische vielfalt</div></LinkContainer>
                    <LinkContainer to="/Gefaehrdeten"><div className="list-group-item">Verteilung gefährdeten Arten</div></LinkContainer>
                    <LinkContainer to="/Waldarten"><div className="list-group-item">Verteilung Waldarten</div></LinkContainer>
                    <LinkContainer to="/Siedlungarten"><div className="list-group-item">Verteilung Siedlungstypischen Arten</div></LinkContainer>
                    <LinkContainer to="/Baumarten"><div className="list-group-item">Verteilung Baumarten</div></LinkContainer>        
                </ul>
            </div>
            
            <fieldset className="d-none scheduler-border">
                <legend className="scheduler-border">Flib Taxon Infos</legend>
                
                <div className="card">
                    <div style={{textAlign:'center',padding:'.5em'}}>
                        <button type="text" name="bt_updateflibtaxon" className="btn btn-outline-success btn-block">Aktualisierung</button>
                        <div id="updprog" className="progress d-none" width="0" height="2px">
                              <div className="progress-bar progress-bar-striped active"  role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{width: '100%', height:'5px'}}>
                              </div>
                        </div>
                    </div>
                </div>
            </fieldset>	
        
        </div>
    </div>       
    )
}

export default  withRouter(SearchContent);