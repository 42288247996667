import React from 'react'

function SiedlungartenCnt() {
    return (
		<div className="colonne">
            <h2>Florenatlas der Stadt Bern</h2>
            <div className="tbsContainer p-4" style={{marginTop:'-10px'}}>
			    <div className="col-md-12" >
                   <h2>Verteilung der Siedlungstypischen Arten</h2>
                   <p></p>          
			    </div>
            </div>
        </div>
    )
}

export default SiedlungartenCnt
