import React from 'react';
import DataTable from './DataTable';
import { connect } from "react-redux";
import { getData } from "../api/actions/index";


class SearchTblCnt extends React.Component {

  componentDidMount() {
    this.props.getData();
  }
  
  
  render(){
    console.log("SearchTbl", this.props.articles);
    return (
              <div className="colonne recherche">
                <h2>Florenatlas der Stadt Bern</h2>
                <div className="col-md-12" >
                  <h2>Art abfragen</h2>
                  {
                      
                    ( this.props.articles !==  undefined) ? <DataTable data={this.props.articles} /> : ''
                   
               }
                </div>
              </div>
            )

  }
}

function mapStateToProps(state) {
  return {
    articles: state.remoteArticles[0]
  };
}



export default connect(
  mapStateToProps,
  { getData }
)(SearchTblCnt);
